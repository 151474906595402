import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
} from '@mui/material';
import {PieChart, Pie, Cell, Label, LabelProps, Text} from 'recharts';
import {StatusCount, TagData, VehicleStatus} from 'pages/softPrio/types';
import {format} from 'date-fns';
import {sv} from 'date-fns/locale';
import { useTheme} from '@mui/styles';
// Define the TableData type
interface PieData {
  name: string;
  value: number;
  color: string;
}
// Sample data for the doughnut chart
const pieData = [
  {name: 'Good', value: 60, color: '#4caf50'},
  {name: 'Alert', value: 30, color: '#ffb300'},
  {name: 'Warning', value: 10, color: '#f44336'},
];
const convertToPieData = (data: StatusCount): PieData[] => {
  return [
    {name: 'Good', value: data.good, color: '#4caf50'},
    {name: 'Alert', value: data.alert, color: '#ffb300'},
    {name: 'Warning', value: data.warning, color: '#f44336'},
  ];
};
// Custom Label Component
interface CustomLabelProps extends LabelProps {
  x: number;
  y: number;
  viewBox: {
    cx: number;
    cy: number;
  };
}
interface DetectorProps {
  singleProcessedTag: VehicleStatus;
}
const gettingAverageOfSignals = (value: number, count: number): number => {
  const result = (value / count) * 100;
  return parseFloat(result.toFixed(1));
};
function getSignalColor(signalValue: number): string {
  if (signalValue > -65) {
    // above -65
    return '#379C54';
  } else if (signalValue <= -65 && signalValue >= -75) {
    // between -65 and -75
    return '#CBA70E';
  } else if (signalValue < -75 && signalValue >= -85) {
    // between -75 and -85
    return '#fab157';
  } else if (signalValue < -85 && signalValue >= -95) {
    // between -85 and -95
    return '#FA5760';
  } else {
    // below -95
    return ''; // Default case, you can set this to any color or handle differently
  }
}
const extractDetectorIds = (tagId:number) => {

  const detectorsWithTags = JSON.parse(
    localStorage.getItem('detectorsWithTags') || '[]',
  );
   const matchedDetector = detectorsWithTags.find((detector) =>
    detector.tags.some((tag) => tag.decimalTagID === tagId)
  );

  return matchedDetector ? matchedDetector.detector : null; // 
};

const DetectorTable: React.FC<DetectorProps> = ({ singleProcessedTag }) => {
  const theme = useTheme(); // Access the theme

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'start',
        minHeight: '100vh',
        width: '100%',
        padding: theme.spacing(3),
      }}
    >
      <Box
        sx={{
          width: '90%',
          backgroundColor: theme.palette.background.paper,
          padding: theme.spacing(3),
          boxShadow: theme.shadows[3],
          borderRadius: theme.shape.borderRadius,
        }}
      >
        <Grid container justifyContent="center" spacing={3}>
          <Grid item xs={12} md={4} style={{ position: 'relative', textAlign: 'center' }}>
            <PieChart width={200} height={200}>
              <Pie
                data={convertToPieData(singleProcessedTag.signalAverage)}
                innerRadius={70}
                outerRadius={90}
                dataKey="value"
                cx="50%"
                cy="50%"
              >
                <Label
                  value={singleProcessedTag.vehicleid}
                  position="center"
                  style={{
                    textAnchor: 'middle',
                    fontSize: '3rem',
                    fontWeight: 'bold',
                    fill: theme.palette.text.primary,
                  }}
                />
                {convertToPieData(singleProcessedTag.signalAverage).map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
            </PieChart>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box sx={{ pt: theme.spacing(3) }}>
              <Typography variant="h6" sx={{ color: theme.palette.success.main }}>
                ✔ Good:{' '}
                {gettingAverageOfSignals(
                  singleProcessedTag.signalAverage.good,
                  singleProcessedTag.count
                )}
                %
              </Typography>
              <Typography variant="h6" sx={{ color: theme.palette.warning.main }}>
                ⚠ Alert:{' '}
                {gettingAverageOfSignals(
                  singleProcessedTag.signalAverage.alert,
                  singleProcessedTag.count
                )}
                %
              </Typography>
              <Typography variant="h6" sx={{ color: theme.palette.error.main }}>
                ❌ Warning:{' '}
                {gettingAverageOfSignals(
                  singleProcessedTag.signalAverage.warning,
                  singleProcessedTag.count
                )}
                %
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <TableContainer
          component={Paper}
          sx={{
            mt: theme.spacing(4),
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                {['Detector', 'Tag', 'Apparatrum', 'Omsändningar', 'Datum', 'RSSI-värde'].map(
                  (header, index) => (
                    <TableCell
                      key={index}
                      sx={{ color: theme.palette.text.primary, textAlign: 'center' }}
                    >
                      {header}
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {singleProcessedTag.items.map((row, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ color: theme.palette.text.primary, textAlign: 'center' }}>
                    {extractDetectorIds(row.eventData.tag_id)}
                  </TableCell>
                  <TableCell sx={{ color: theme.palette.text.primary, textAlign: 'center' }}>
                    {row.eventData.tag_id}
                  </TableCell>
                  <TableCell sx={{ color: theme.palette.text.primary, textAlign: 'center' }}>
                    {row.eventData.udp_packets.destination_address}
                  </TableCell>
                  <TableCell sx={{ color: theme.palette.text.primary, textAlign: 'center' }}>
                    {row.eventData.udp_packets.resend_flag
                      ? row.eventData.udp_packets.buffer === 0
                        ? 'True'
                        : row.eventData.udp_packets.buffer
                      : 'Inga'}
                  </TableCell>
                  <TableCell sx={{ color: theme.palette.text.primary, textAlign: 'center' }}>
                    {format(row.detectionTime, 'yyyy-MM-dd HH:mm:ss', { locale: sv })}
                  </TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>
                    <Typography
                      sx={{
                        color: getSignalColor(row.eventData.udp_packets.rssi, theme),
                      }}
                    >
                      {row.eventData.udp_packets.rssi} dbm
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default DetectorTable;