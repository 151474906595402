import {useNotLoadingEffect, useNotLoadingMemo} from 'utils/hooks/useMemoHooks.ts';
import {TrainApiVisionRequestProps, VisionMeta, VisionMetaMinimized} from 'types/vision/visionMeta';
import {WeekYear} from 'types/datetime/weekYear';
import {StateSetter} from 'types/hookHelpers/stateSetter';
import {ts} from 'appUtils/typeUtils/clsOrType.ts';
import {useCemitApiSwrResolveData} from 'async/cemitAppAsync/cemitAppHooks/cemitApiHooks/apiResolverHooks.ts';
import {always, ascend, cond, equals, filter, head, lensProp, mean, props, sortWith, T, unless} from 'ramda';
import {CemitApiVisionRoute} from 'apis/cemitApis/trainApi/visionApi.ts';
import {OrganizationProps} from 'types/propTypes/organizationPropTypes/organizationProps';
import {overClassOrType} from 'utils/functional/cemitTypenameFunctionalUtils.ts';
import {ForwardBackward} from 'types/navigation/directionEnum..ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {differenceInWeeks, isWithinInterval} from 'date-fns';
import {lengthAsBoolean} from 'utils/functional/functionalUtils.ts';
import {SyntheticEvent} from 'react';
import {VisionSpatialNavigatorProps} from 'pages/vision/visionComponents/navigation/VisionSpatialNavigator.tsx';
import {MapProps} from 'react-map-gl';
import {TrainProps} from 'types/propTypes/trainPropTypes/trainProps';
import {mapboxZoomToSources} from 'utils/map/mapboxSourceUtils.ts';

/**
 * Queries the Vision API for nearbyPictures
 * @param loading
 * @param organizationProps
 * @param weekYear
 * @param trackDistanceInMeters
 * @param trackSectionId
 * @param prevApiParams
 * @param setPrevApiParams
 * @param setVisionMetas
 * @param setVisionMeta
 * @param visionMetasError
 * @param setVisionMetasError
 * @param meterDelta +/- meters to query for from trackDistanceInMeters
 * @param latestClick
 * @param setLatestClick
 * @param setTrackDistanceInMeters
 */
export const useNotLoadingQueryVisionNearbyPicturesApi = (
  loading: boolean,
  organizationProps: OrganizationProps,
  weekYear: WeekYear,
  trackDistanceInMeters: number,
  trackSectionId: string,
  prevApiParams: VisionMetaMinimized,
  setPrevApiParams: StateSetter<VisionMetaMinimized>,
  setVisionMetas: StateSetter<VisionMeta[]>,
  setVisionMeta: StateSetter<Perhaps<VisionMeta>>,
  visionMetasError: any,
  setVisionMetasError: StateSetter<any>,
  meterDelta: number,
  latestClick: Perhaps<ForwardBackward>,
  setLatestClick: StateSetter<Perhaps<ForwardBackward>>,
  setTrackDistanceInMeters: StateSetter<number>
) => {
  const dependencies = [
    trackDistanceInMeters,
    trackSectionId,
    weekYear,
    meterDelta,
    latestClick
  ] as const;
  const visionProps: TrainApiVisionRequestProps = useNotLoadingMemo(
    loading,
    (
      trackDistanceInMeters,
      trackSectionId,
      weekYear,
      meterDelta,
      latestClick
    ): TrainApiVisionRequestProps => {
      return {
        // Make this just over the previous trackDistanceInMeters so we get a new image
        trackDistanceInMeters: trackDistanceInMeters + 1,
        trackSectionId,
        weekYear,
        meterDelta,
        latestClick,
        ...cond([
          [
            // If the user clicked forward, we make trackDistanceInMeters the lower limit
            equals(ForwardBackward.forward),
            always({
              track_distance_meters_upper: trackDistanceInMeters + 5000,
              track_distance_meters_lower: trackDistanceInMeters + 1
            })
          ],
          [
            // If the user clicked backed, we make trackDistanceInMeters the upper limit
            equals(ForwardBackward.backward),
            always({
              track_distance_meters_upper: trackDistanceInMeters - 1,
              track_distance_meters_lower: Math.max(0, trackDistanceInMeters - 5000)
            })
          ],
          [
            // If latestClick wasn't set to a direction, we are querying by changing weeks, so query around a buffer
            // of meterDelta
            T,
            always({
              track_distance_meters_upper: trackDistanceInMeters + meterDelta,
              track_distance_meters_lower: Math.max(
                0,
                trackDistanceInMeters - meterDelta
              )
            })
          ]
        ])(latestClick)
      } as TrainApiVisionRequestProps;
    },
    dependencies
  );

  const response = useCemitApiSwrResolveData<CemitApiVisionRoute>(
    loading,
    // Hack to use visionAPi with
    overClassOrType(
      lensProp('sourceKey'),
      (sourceKey) => sourceKey + 'VISION',
      organizationProps.userState
    ),
    'vision',
    visionProps,
    undefined
  );

  const {data: visionMetas, error} = response;
  if (error) {
    setVisionMetasError(error);
  }
  useNotLoadingEffect(
    loading || !visionMetas || Boolean(error),
    (
      prevApiParams,
      visionMetas,
      weekYear,
      trackDistanceInMeters,
      trackSectionId,
      track_distance_meters_lower,
      track_distance_meters_upper
    ) => {
      if (
        visionMetas &&
        (!equals(prevApiParams?.weekYear, weekYear) ||
          !equals(prevApiParams?.trackDistanceInMeters, trackDistanceInMeters) ||
          !equals(prevApiParams?.trackSectionId, trackSectionId) ||
          !equals(
            prevApiParams?.track_distance_meters_lower,
            track_distance_meters_lower
          ) ||
          !equals(
            prevApiParams?.track_distance_meters_upper,
            track_distance_meters_upper
          ))
      ) {
        // Sort first by closest image in number of weeks awayn and then distance and then in time.
        // This should work since we either get a set of images within the current week or outside the current week
        const sortedVisionMetas = sortWith([
          ascend((visionMeta: VisionMeta) => {
            return Math.abs(
              differenceInWeeks(
                visionMeta.timestamp,
                mean(props(['start', 'end'], weekYear.dateInterval))
              )
            );
          }),
          ascend((visionMeta: VisionMeta) => {
            return Math.abs(visionMeta.trackDistanceInMeters - trackDistanceInMeters);
          })
        ])(visionMetas);

        const filteredVisionMetas = filter((sortedVisionMeta: VisionMeta) => {
          return isWithinInterval(weekYear.dateInterval, sortedVisionMeta.timestamp);
        }, sortedVisionMetas);
        // If there are any VisionMetas within the current week, use only them. Otherwise return
        // everything outside the closest week since that's the best we can offer
        const finalVisionMetas = unless(
          lengthAsBoolean,
          always(sortedVisionMetas)
        )(filteredVisionMetas);

        setVisionMetas(finalVisionMetas);
        // The current VisionMeta is the one within the current WeekYear that is closest to the requested trackDistanceInMeters
        const maybeVisionMeta: Perhaps<VisionMeta> = head(finalVisionMetas);
        setVisionMeta(maybeVisionMeta);
        // Updates the props
        if (maybeVisionMeta) {
          setTrackDistanceInMeters(maybeVisionMeta.trackDistanceInMeters);
        }
        // Prevents running again until a direction button is pushed
        setLatestClick(undefined);
        // Do this so we don't set the response over and over
        // TODO we shouldn't get in here if the response hasn't changed
        setPrevApiParams(
          ts<VisionMetaMinimized>({
            trackDistanceInMeters,
            trackSectionId,
            weekYear,
            track_distance_meters_lower,
            track_distance_meters_upper
          })
        );
      }
    },
    [
      prevApiParams,
      visionMetas as VisionMeta[],
      weekYear,
      trackDistanceInMeters,
      trackSectionId,
      visionProps?.track_distance_meters_lower,
      visionProps?.track_distance_meters_upper
    ] as const
  );
};

/**
 * Creates a handleSpatialNavigatorClick based on the given direction
 * @param trainProps
 * @param mapProps
 */
export const visionNavigatorProps = (trainProps: TrainProps, mapProps: MapProps) => {
  const visionProps = trainProps.visionProps;
  const handleSpatialNavigatorClick = (
    event: SyntheticEvent,
    direction: ForwardBackward
  ) => {
    if (direction === ForwardBackward.forward) {
      visionProps.setLatestClick(ForwardBackward.forward);
      mapboxZoomToSources(
        mapProps.trainMap,
        trainProps.visionProps.visionMapSourceVisuals
      );
      // visionProps.setTrackDistanceInMeters((prev: number) => {
      //   // Search in front of the previous value
      //   // TODO prev is currently the single returned meters value from the api
      //   return prev + visionProps.visionConfig.meterDelta;
      // });
    } else if (direction === ForwardBackward.backward) {
      visionProps.setLatestClick(ForwardBackward.backward);
      mapboxZoomToSources(
        mapProps.trainMap,
        trainProps.visionProps.visionMapSourceVisuals
      );
      // visionProps.setTrackDistanceInMeters((prev: number) => {
      //   // Search in back of the previous value
      //   // TODO prev is currently the single returned meters value from the api
      //   return prev - visionProps.visionConfig.meterDelta;
      // });
    }
  };
  return ts<VisionSpatialNavigatorProps>({
    handleSpatialNavigatorClick
  });
};
