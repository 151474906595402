import React, {useState, useEffect} from 'react';
import {
  Box,
  Typography,
  Select,
  MenuItem,
  IconButton,
  Divider,
  Button,
  Stack,
} from '@mui/material';
import DetectorTable from '../DetectorTable/DetectorTable.tsx';
import { useTheme} from '@mui/styles';
import {ArrowForward, ArrowBack} from '@mui/icons-material';
import {EventIcon} from 'components/apps/cemitAppComponents/svgIconComponents/sidebar/eventIcon.tsx';
// import {DateTimePicker, LocalizationProvider} from '@mui/x-date-pickers';
// import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs/index.js';

import {
  TagData, 
  StatusCount,
  ProcessedData,
  VehicleStatus,
  StatusDetails, TagDataInstance
} from 'pages/softPrio/types/index.ts';
import dayjs, {Dayjs} from 'dayjs';
import utc from 'dayjs/plugin/utc.js';
import timezone from 'dayjs/plugin/timezone.js';
// import {AppSettings} from 'config/appConfigs/appSettings.ts';
import SideBarTrainCard from './SideBarTrainCard.tsx';
import useFetchAllTags from 'pages/softPrio/hooks/useFetchAllTags.ts';
dayjs.extend(utc);
dayjs.extend(timezone);
interface SideBarProps {
  functionName: string;
  fetchedTags: TagDataInstance[] | undefined;
  selectedTram: () => void;
  isFullScreenForTramDetail: (isFullScreen: boolean) => void;
}
function stripPrefix(value: string): string {
  const valueStr = value.toString(); // Convert the number to a string
  if (valueStr.startsWith('10')) {
    // Check if it starts with '10'
    return valueStr.slice(2); // Remove the first two characters and convert back to number
  }
  return value; // If it doesn't start with '10', return the original value
}


const Sidebar: React.FC<SideBarProps> = ({
  functionName,
  fetchedTags,
  selectedTram,
  isFullScreenForTramDetail,
}) => {
  const theme = useTheme(); // Access the theme
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [alarmType, setAlarmType] = useState('Alla typer');
  const [timeRange, setTimeRange] = useState('1W');
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [openPicker, setOpenPicker] = useState(false);
  const { data } = useFetchAllTags();
  const [processedTags, setProcessedTags] = useState<VehicleStatus[]>();
  const [singleProcessedTag, setSingleProcessedTag] = useState<VehicleStatus>();

  const handleFullScreenToggle = () => {
    setIsFullScreen(!isFullScreen);
    isFullScreenForTramDetail(!isFullScreen);
  };
  // const handleDateChange = (date: Date | null) => {
  //   setSelectedDate(date);
  // };
  // const getDistinctIds = (data: TagData): number[] => {
  //   const ids = new Set<number>();

  //   data.data.forEach((item) => {
  //     ids.add(item.eventData.old_vehicle_id);
  //   });

  //   return Array.from(ids);
  // };
  // const getDistinctObjects = (data: TagData): TagData[] => {
  //   const vehicleMap: Map<number, TagData> = new Map();

  //   data.data.forEach((item) => {
  //     const oldVehicleId = item.eventData.old_vehicle_id;
  //     const existingItem = vehicleMap.get(oldVehicleId);

  //     if (
  //       !existingItem ||
  //       new Date(item.eventData.server_timestamp) >
  //         new Date(item.eventData.server_timestamp)
  //     ) {
  //       vehicleMap.set(oldVehicleId, item);
  //     }
  //   });

  //   // Convert map values to an array and sort by detectionTime
  //   return Array.from(vehicleMap.values()).sort(
  //     (a, b) => new Date(b.detectionTime).getTime() - new Date(a.detectionTime).getTime(),
  //   );
  // };
  // Function to filter and calculate average rssi
  // Function to filter and calculate average rssi
  // Function to process data and get average counts of each status
  const processDataAndCalculateAverages = (data: TagDataInstance[]): ProcessedData => {
    const vehicleMap: Map<
      number,
      {
        rssiSum: number;
        count: number;
        vehicleid: number;
        items: TagDataInstance;
        signalAverage: StatusCount;
      }
    > = new Map();
    data.forEach((item:any) => {
      const oldVehicleId = stripPrefix(item.eventData.old_vehicle_id.toString());
      const rssi = item.eventData.udp_packets.rssi;
      const statusCounts: StatusDetails = {warning: 0, alert: 0, good: 0};
      if (!vehicleMap.has(oldVehicleId)) {
        vehicleMap.set(oldVehicleId, {
          rssiSum: 0,
          count: 0,
          vehicleid: oldVehicleId,
          items: [],
          signalAverage: statusCounts,
        });
      }
      const vehicleData = vehicleMap.get(oldVehicleId)!;
      const averageRssi = rssi / vehicleData.count;
      if (averageRssi > -75) {
        vehicleData.signalAverage.alert += 1;
      } else if (averageRssi > -65) {
        vehicleData.signalAverage.alert += 1;
      } else {
        vehicleData.signalAverage.good += 1;
      }
      vehicleData.rssiSum += rssi;
      vehicleData.count += 1;
      vehicleData.items.push(item);
    });
    const statusCounts: StatusDetails = {warning: 0, alert: 0, good: 0};
    Array.from(vehicleMap.values()).forEach((vehicleData) => {
      const averageRssi = vehicleData.rssiSum / vehicleData.count;
      if (averageRssi > 75) {
        statusCounts.warning += 1;
      } else if (averageRssi > 65) {
        statusCounts.alert += 1;
      } else {
        statusCounts.good += 1;
      }
    });
    const totalVehicles = statusCounts.warning + statusCounts.alert + statusCounts.good;
    const averageCounts = {
      warning: totalVehicles > 0 ? statusCounts.warning / totalVehicles : 0,
      alert: totalVehicles > 0 ? statusCounts.alert / totalVehicles : 0,
      good: totalVehicles > 0 ? statusCounts.good / totalVehicles : 0,
    };

    // Convert map values to an array and sort by server_timestamp
    const distinctObjects = Array.from(vehicleMap.values()).sort((a, b) => {
      const bFirstItem = b.items[0]; // Explicitly access the first item
      const aFirstItem = a.items[0];
    
      // Ensure `bFirstItem` and `aFirstItem` exist before accessing their properties
      if (bFirstItem && aFirstItem) {
        return (
          new Date(bFirstItem.eventData.server_timestamp).getTime() -
          new Date(aFirstItem.eventData.server_timestamp).getTime()
        );
      }
    
      // If either is missing, treat it as less important
      return bFirstItem ? -1 : aFirstItem ? 1 : 0;
    });
    return {
      distinctObjects,
    
    };
  };
  useEffect(() => {
    if (fetchedTags !== undefined) {
      const data = fetchedTags.data
      const {distinctObjects} = processDataAndCalculateAverages(data);
      setProcessedTags(distinctObjects);
    }
  }, [fetchedTags]);
  const togglePicker = () => {
    setOpenPicker(!openPicker);
  };

  const handleAlarmTypeChange = (event: React.ChangeEvent<{value: unknown}>) => {
    setAlarmType(event.target.value as string);
  };

  const handleTimeRangeChange = (event: React.ChangeEvent<{value: unknown}>) => {
    setTimeRange(event.target.value as string);
  };


  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
        height: '100vh',
        padding: theme.spacing(2),
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100%',
        width: isFullScreen ? '100%' : '33.33%',
        transition: 'width 0.3s ease',
      }}
    >
      {/* Header */}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ width: '100%' }}
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography variant="h6" sx={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>
            SoftPrio
          </Typography>
          <Typography variant="h6" sx={{ color: theme.palette.text.secondary, fontWeight: 'bold' }}>
            &gt;
          </Typography>
          <Typography variant="h6" sx={{ color: theme.palette.text.primary }}>
            {functionName}
          </Typography>
        </Stack>

        {singleProcessedTag && (
          <IconButton
            sx={{
              backgroundColor: theme.palette.warning.main,
              '&:hover': { backgroundColor: theme.palette.warning.dark },
            }}
            onClick={handleFullScreenToggle}
          >
            {isFullScreen ? (
              <ArrowBack sx={{ color: theme.palette.text.primary }} />
            ) : (
              <ArrowForward sx={{ color: theme.palette.text.primary }} />
            )}
          </IconButton>
        )}
      </Stack>

      <Divider sx={{ backgroundColor: theme.palette.divider, mt: 1 }} />

      {/* Filter Section */}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ marginTop: theme.spacing(2), width: '100%' }}
        spacing={2}
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <Select
            sx={{
              color: theme.palette.text.primary,
              minWidth: '8em',
              height: '2.5rem',
              '& .MuiSvgIcon-root': {
                color: theme.palette.text.primary,
              },
            }}
            disableUnderline
            value={alarmType}
            onChange={handleAlarmTypeChange}
            size="small"
          >
            <MenuItem value="Alla typer">Alla typer</MenuItem>
            <MenuItem value="Online">Online</MenuItem>
            <MenuItem value="off-line">Off-line</MenuItem>
          </Select>
          <Button
            onClick={() => setAlarmType('Alla typer')}
            size="small"
            style={{
              backgroundColor: theme.palette.warning.main,
              color: theme.palette.warning.contrastText,
              borderRadius: 20,
              padding: '2px 8px',
            }}
          >
            {alarmType} ✕
          </Button>
        </Stack>

        <Stack direction="row" spacing={2} alignItems="center">
          <Typography>
            {startDate && endDate
              ? `${startDate.toLocaleDateString()} to ${endDate.toLocaleDateString()}`
              : 'Select Date'}
          </Typography>
          <IconButton onClick={togglePicker}>
            <EventIcon style={{ fill: theme.palette.warning.main }} />
          </IconButton>
        </Stack>
      </Stack>

      <Divider sx={{ backgroundColor: theme.palette.divider, mb: 1, marginTop: theme.spacing(0.2) }} />

      <Stack direction="row" alignItems="start">
        <Box
          sx={{
            width: isFullScreen ? '33%' : '100%',
            maxWidth: '100%',
            borderRight: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Box
            sx={{
              height: '100vh',
              backgroundColor: theme.palette.background.paper,
              padding: theme.spacing(1),
              borderRadius: theme.shape.borderRadius,
            }}
          >
            <SideBarTrainCard
              processedTags={processedTags!}
              selectedTag={singleProcessedTag}
              onSelectTag={(e) => {
                setSingleProcessedTag(e);
                selectedTram(e);
              }}
            />
          </Box>
        </Box>
        {isFullScreen && <DetectorTable singleProcessedTag={singleProcessedTag} />}
      </Stack>
    </Box>
  );
};

export default Sidebar;
