import React, {useState, useEffect} from 'react';
import {
  AppBar,
  Toolbar,
  Stack,
  MenuItem,
  Menu,
  Button,
  Typography,
  Box,
  FormControlLabel,
  Switch,
} from '@mui/material';

import {ArrowDropDown, LightMode, DarkMode} from '@mui/icons-material';
import Sidebar from './components/sidepanel/SideBar.tsx';
import StatusPage from './StatusPage.tsx';
import useExcelToGeoJson from './hooks/useExcelToGeoJson.ts';
import geoJsonSoftPrio from './tempFile/softPrioTrack.geojson';
import useFetchAllTags from './hooks/useFetchAllTags.ts';
import {DirectionPoint, Feature, DetectorData, VehicleStatus} from './types';
import {ThemeEnum} from 'theme/lightDarkTheme.ts';
import tagglist from './tempFile/tagglista20240627.csv';
import {useTheme} from '@mui/styles';
const StausPageConatiner: React.FC<{
  spaceId: string | null;
  pointId: string | null;
  toggleLocalTheme: any;
  localTheme: string;
}> = ({toggleLocalTheme, localTheme}) => {
  //
  const [detectors, setDetectors] = useState([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedCdc] = useState<string>(''); // State to hold selected CDC
  const [singleProcessedTag, setSingleProcessedTag] = useState<
    VehicleStatus | undefined
  >();
  const [submenuAnchorEl, setSubmenuAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedValueForFunction, setSelectedValueForFunction] =
    useState<string>('SOFTPRIO');
  const [pointGeoJsonDataV, setGeoJsonDataV] = useState(null);
  const [selectedTag] = useState(null);
  const [isFullScreenForTramDetail, setIsFullScreenForTramDetail] =
    useState<Boolean>(false);
  const {data} = useFetchAllTags();
  const {pointsGeoJsonData, dataLoaded, convertExcelToGeoJson} =
    useExcelToGeoJson(tagglist);

  const theme = useTheme();

  useEffect(() => {
    convertExcelToGeoJson();
  }, [convertExcelToGeoJson]);

  useEffect(() => {
    if (dataLoaded) {
      setGeoJsonDataV(pointsGeoJsonData);
    }
  }, [dataLoaded, pointsGeoJsonData]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (value: string | null) => {
    setAnchorEl(null);
    setSubmenuAnchorEl(null);
    if (value) setSelectedValueForFunction(value);
  };

  const handleSubmenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setSubmenuAnchorEl(event.currentTarget);
  };

  const handleSubmenuClose = () => {
    setSubmenuAnchorEl(null);
  };

  const addDetectorData = (newData: any) => {
    setDetectors((prevDetectors) => [...prevDetectors, newData]);
  };
  // const [isSliderChecked, setSliderChecked] = useState(localTheme === ThemeEnum.softpriolight);

  // const onSliderChange = (event) => {
  //   setSliderChecked(event.target.checked);
  //   console.log('Slider toggled:', event.target.checked);
  // };
  return (
    <Stack sx={{width: '100%', height: '100%'}}>
      <AppBar
        position="static"
        sx={{
          backgroundColor: theme.palette.background.default,
          borderBottom: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Toolbar>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
            endIcon={<ArrowDropDown />}
            sx={{fontSize: '16px', fontWeight: 500}}
          >
            {selectedValueForFunction}
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => handleClose(null)}
          >
            <MenuItem
              sx={{color: theme.palette.text.primary}}
              onClick={() => handleClose('Detektorer')}
            >
              Detektorer
            </MenuItem>
            <MenuItem
              sx={{color: theme.palette.text.primary}}
              onMouseEnter={handleSubmenuOpen}
            >
              Radioprestanda
              <Typography sx={{marginLeft: 'auto', color: theme.palette.text.primary}}>
                {' >'}
              </Typography>
              <Menu
                id="nested-menu"
                anchorEl={submenuAnchorEl}
                keepMounted
                open={Boolean(submenuAnchorEl)}
                onClose={handleSubmenuClose}
                
                sx={{
                  '& .MuiPaper-root': {
                    backgroundColor: theme.palette.background.paper,
                    position: 'absolute',
                    left: '100%',
                    top: 0,
                    marginLeft: '10px',
                  },
                }}
              >
                <MenuItem
                  sx={{color: theme.palette.text.primary}}
                 
                  onClick={() => handleClose('Radiokvalitet')}
                >
                  Radiokvalitet
                </MenuItem>
                <MenuItem
                  sx={{color: theme.palette.text.primary}}
                
                  onClick={() => handleClose('Underpresterande')}
                  onMouseLeave={handleSubmenuClose}
                >
                  Underpresterande
                </MenuItem>
              </Menu>
            </MenuItem>
            <MenuItem
              sx={{color: theme.palette.text.primary}}
              onClick={() => handleClose('Kraftförsörjning')}
            >
              Kraftförsörjning
            </MenuItem>
            <MenuItem
              sx={{color: theme.palette.text.primary}}
              onClick={() => handleClose('Missade taggar')}
            >
              Missade taggar
            </MenuItem>
            <MenuItem
              sx={{color: theme.palette.text.primary}}
              onClick={() => handleClose('Live Status')}
            >
              Live Status
            </MenuItem>{' '}
          </Menu>

          {/* Slider Switch with Icons added to the right */}
          <Box
            sx={{
              marginLeft: 'auto',
              marginRight: '2rem',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <LightMode sx={{color: theme.palette.warning.main, marginRight: 1}} />
            <FormControlLabel
              control={
                <Switch
                  checked={localTheme === 'dark'}
                  onChange={toggleLocalTheme}
                  color="warning" // Uses the warning color from the theme
                />
              }
              label={
                <DarkMode
                  sx={{
                    color: theme.palette.text.primary,
                    marginLeft: 1,
                  }}
                />
              }
              labelPlacement="end"
              sx={{
                '& .MuiTypography-root': {
                  marginRight: '8px',
                },
              }}
            />
          </Box>
        </Toolbar>
      </AppBar>

      <Stack direction="row" sx={{flexGrow: 1}}>
        <Sidebar
          functionName={selectedValueForFunction}
          isFullScreenForTramDetail={(isFullScreen) =>
            setIsFullScreenForTramDetail(isFullScreen)
          }
          selectedTram={(singleTram: VehicleStatus) => {
            setSingleProcessedTag(singleTram);
          }}
          fetchedTags={data!}
        />
        {pointGeoJsonDataV && !isFullScreenForTramDetail && (
          <StatusPage
            functionName={selectedValueForFunction}
            directionPoints={pointGeoJsonDataV}
            selectedTag={selectedTag}
            selectedCDC={selectedCdc}
            singleProcessedTag={singleProcessedTag}
            onAddDetector={addDetectorData}
            fetchedTags={data!}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default StausPageConatiner;
