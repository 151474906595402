// SandboxTable.tsx
import React, {useEffect, useState} from 'react';
import {
  TableBody,
  TableCell,
  TableRow,
  Button,
  Chip,
  CircularProgress,
} from '@mui/material';
import useDataStreamsForSandBox from './hooks/useDataStreamsForSandBox.ts';
import {Datastream} from 'pages/sandbox/types/datastream';
import {HistoricalData} from 'pages/sandbox/types/historicalData';
import {StatusTableProps} from 'pages/sandbox/types/statusTableProps';

const statusColors: {
  [key: string]:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'warning'
    | 'info'
    | 'success';
} = {
  'ACTION REQUIRED': 'error',
  GOOD: 'success',
  MONITOR: 'warning',
};
interface SandBoxStatus {
  sandBoxStatusColors: string[];
  sandBoxStatus: string;
}

// Array of predefined colors
const colors = ['#FF5733', '#33FF57', '#3357FF', '#FF33A1', '#A133FF', '#33FFF5'];

const createHistoricalData = (datastreamValue: Datastream): HistoricalData[] => {
  const datastream = datastreamValue.datastream;
  const color = datastreamValue.color
  return [
    {
      date: 'Previous Month',
      level: datastream.last_30_days_previous,
      sandbox: datastream.labels.position,
      color: color
    },
    {date: 'Month', level: datastream.last_30_days, sandbox: datastream.labels.position},
    {
      date: 'Last Week',
      level: datastream.last_7_days,
      sandbox: datastream.labels.position,
      color : color
    },
  ];
};
const combineSandboxData = (datastreams: Datastream[]): HistoricalData[] => {

  return datastreams.flatMap((datastream) => createHistoricalData(datastream));
};
const getStatusColor = (level: number): string => {
  if (level >= 60) return '#4caf50'; // Green for good
  if (level >= 30 && level < 60) return '#ffc107'; // Yellow for fair
  return '#f44336'; // Red for bad
};
const getStatus = (level: number): string => {
  if (level >= 60) return 'GOOD'; // Green for good
  if (level >= 30 && level < 60) return 'MONITOR'; // Yellow for fair
  return 'ACTION REQUIRED'; // Red for bad
};

const generatingSandBoxStatus = (datastreams: Datastream[]): SandBoxStatus => {
  const sandBoxValues = datastreams.map((datastream) =>
    parseInt(datastream.datastream.latest_reading.value, 10),
  );
  const sandBoxStatuses = sandBoxValues.map(getStatusColor);
  const sandBoxLowestValue = Math.min(...sandBoxValues);
  const sandBoxStatus = getStatus(sandBoxLowestValue);

  return {
    sandBoxStatusColors: sandBoxStatuses,
    sandBoxStatus: sandBoxStatus,
  };
};

const SandboxTrainListTable: React.FC<StatusTableProps> = ({
  onSelectTrain,
  selectedTrain,
  trainsData,
}) => {

  const {data, errors, isLoading, refetch, lastFetched} = useDataStreamsForSandBox(trainsData.sandboxIds);
  const [combinedHistoricalData, setCombinedHistoricalData] = useState<HistoricalData[]>(
    [],
  );
  const [sandBoxStatus, setSandBoxStatus] = useState<SandBoxStatus | null>(null);

  useEffect(() => {
    if (!isLoading && data) {
      setCombinedHistoricalData(combineSandboxData(assignColorsToSandboxes(data)));
      setSandBoxStatus(generatingSandBoxStatus(data));
    }
  }, [isLoading, data]);

  const assignColorsToSandboxes = (sandboxes: Datastream[]): Datastream[] => {
    let colorIndex = 0; // Initialize index for cycling through colors
  
    // Map through sandboxes and assign a color
    return sandboxes.map((sandbox) => ({
      ...sandbox,
      color: colors[colorIndex++ % colors.length], // Assign color and cycle through the array
    }));
  };

  return (
    <>
      <TableBody>
        <TableRow>
          <TableCell>
            {isLoading ? (
              <CircularProgress />
            ) : (
              sandBoxStatus && (
                <Chip
                  label={sandBoxStatus.sandBoxStatus}
                  color={statusColors[sandBoxStatus.sandBoxStatus]}
                />
              )
            )}
          </TableCell>
          <TableCell>
            {isLoading ? (
              <CircularProgress />
            ) : (
              sandBoxStatus && (
                <div style={{display: 'flex', alignItems: 'center'}}>
                  {sandBoxStatus.sandBoxStatusColors.map((color, idx) => (
                    <Chip
                      key={idx}
                      sx={{
                        backgroundColor: color,
                        margin: '0 2px',
                        width: '20px',
                        height: '20px',
                      }}
                    />
                  ))}
                </div>
              )
            )}
          </TableCell>
          <TableCell>{lastFetched}</TableCell>
          <TableCell>{trainsData.fleet}</TableCell>
          <TableCell>{trainsData.trainId}</TableCell>
          <TableCell>{trainsData.travellingFrom}</TableCell>
          <TableCell>{trainsData.travellingTo}</TableCell>
          <TableCell>{trainsData.headSandboxPairAvg}</TableCell>
          <TableCell>{trainsData.tailSandboxPairAvg}</TableCell>
          <TableCell>{trainsData.currentPosition}</TableCell>
          <TableCell>
            {!selectedTrain ? (
              <Button
                variant="contained"
                color="primary"
                sx={{marginRight: 1}}
                onClick={() =>
                {
                  const coloredSandboxes = assignColorsToSandboxes(data); // Assign colors
                  onSelectTrain({
                    historicalData: combinedHistoricalData,
                    sandboxes: coloredSandboxes,
                    trainData: trainsData,
                  })
                }
                 
                }
              >
                View More
              </Button>
            ) : (
              <Button
                variant="contained"
                color="secondary"
                sx={{marginRight: 1}}
                onClick={() => onSelectTrain(null)}
              >
                View Less
              </Button>
            )}
            <Button variant="contained" color="secondary">
              Request Fill
            </Button>
          </TableCell>
        </TableRow>
      </TableBody>
    </>
  );
};

export default SandboxTrainListTable;
