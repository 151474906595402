import * as React from 'react';
import {useCallback, useContext, useEffect, useState} from 'react';
import {useTheme} from '@mui/styles';
import {Layer, Map, Marker, Source} from 'react-map-gl';
import {Box, CircularProgress, Grid, Slide, Stack, Typography} from '@mui/material';
import LayerSelector from 'pages/trafficsim/trafficSimComponents/themedComponents/LayerSelector.tsx';
import {
  addRestriction,
  batchUpdateRemoteMapObjects,
  getMapData,
  removeRestriction,
  updateRemoteRestriction,
} from 'pages/trafficsim/api/net/repo.ts';
import RestrictionPin from 'pages/trafficsim/trafficSimComponents/track/svgs/RestrictionPin.tsx';
import {FeedPoint, IFeedPoint} from './FeedPoint.tsx';
import {Station} from './Station.tsx';
import {Section} from './Section.tsx';
import {isEqual} from 'ohash';
import DrawerStyled from 'pages/trafficsim/trafficSimComponents/themedComponents/DrawerStyled.tsx';
import CurrentLimitations from 'pages/trafficsim/trafficSimComponents/limitations/CurrentLimitations.tsx';
import UpcomingLimitations from 'pages/trafficsim/trafficSimComponents/limitations/UpcomingLimitations.tsx';
import Feedpoints from 'pages/trafficsim/trafficSimComponents/feedpointCharacteristics/Feedpoints.tsx';
import AddLimitation from 'pages/trafficsim/trafficSimComponents/limitations/AddLimitation.tsx';
import ReserveFeedpoints from 'pages/trafficsim/trafficSimComponents/reservmatarpunkter/ReserveFeedpoints.tsx';
import History from 'pages/trafficsim/trafficSimComponents/historik/History.tsx';
import collectionOfDividers from 'pages/trafficsim/config/gothenburg/collection-of-dividers.json';
import sektionsisolatorerData from 'pages/trafficsim/config/gothenburg/sektionsisolatorer.json';
import stationNameData from 'pages/trafficsim/config/gothenburg/stationName.json';
import vaxlarData from 'pages/trafficsim/config/gothenburg/vaxlar.json';
import {LoadingButton} from '@mui/lab';
import {Save} from '@mui/icons-material';
import {InfoText} from '../../../utils/info_texts.ts';
import {UserContext, UserContextType} from 'pages/auth/UserContext.ts';
import {UserStateLoaded} from 'types/userState/userState';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {useTranslation} from 'react-i18next';
import {hasTrafficSimAdminAccess} from 'appUtils/authentication/authenticationUtils.ts';
import {TRAFFIC_SIM_PAGE_NAME} from "../../../utils/page_names.ts";
import {Restriction} from "./Restriction.tsx";
import packageJson from '../../../../../../package.json'
import CemitThemeContext from "../../../../../theme/CemitThemeContext.ts";

const MAPBOX_TOKEN =
  'pk.eyJ1IjoiY2VtaXR1c2VyIiwiYSI6ImNsdDczNWlkODBlY3Uya212dGdzeWZ6bzkifQ.5mhzZOJTySDiKcDjMHHd8w'; // Set your mapbox token here

const availableLayers = [
  {id: 'vaxlar-layer', name: 'Vaxlar'},
  {id: 'station-name-layer', name: 'Spårvagnsstationens Namn'},
  {id: 'stationId', name: 'Likriktarstation '},
  {id: 'feedpointsId', name: 'Matarpunkter '},
];

let localDataStore: any = undefined;

export enum MapComponentActionType {
  OPEN = 1,
  CLOSE = 0,
}

export enum InfoType {
  SUCCESS = 1,
  FAILURE = 0,
}

export enum DeepSectionSearchType {
  CLOSED_SECTIONS = 1,
  SAFE_SECTIONS = 0,
}

export enum DuplicateOperationType {
  IDs = 1,
  TRACK_OBJECT = 0,
}

const appVersion = packageJson.versions.mpk;

export default function TrafficSimMap({
                                        drawerOpen,
                                        setDrawerOpen,
                                        activeSubPage,
                                        setActiveSubPage,
                                        mapStyle,
                                        mapRef,
                                        setSearchEntries,
                                      }) {
  const theme = useTheme();
  const {themeName} = useContext(CemitThemeContext);
  const {t} = useTranslation();

  const [dataToEdit, setRestrictionDataToEdit] = useState({});
  const [uRestrictionData, setURestrictionData] = useState([]);

  const [feedPoints, setFeedPoints] = useState<any[]>([]);
  const [stations, setStations] = useState<any[]>([]);
  const [sections, setSections] = useState<any[]>([]);

  const [sectionChangeLog, setSectionChangeLog] = useState<string[]>([]);
  const [stationChangeLog, setStationChangeLog] = useState<string[]>([]);
  const [feedPointsChangeLog, setFeedPointsChangeLog] = useState<string[]>([]);

  const [{showSlider, info, infoType}, setInfoSliderProps] = useState<any>({
    showSlider: false,
    info: '',
    infoType: -1,
  });

  const [showFeedPoints, setShowFeedPoints] = useState(true);
  const [showStations, setShowStations] = useState(true);
  const [isPublishing, setPublishState] = useState(false);
  const [mapReference, setMapReference] = useState(null)

  const userState = useContext<Perhaps<UserContextType>>(UserContext)!
    .userState as UserStateLoaded;

  const defaultCoordinate = [11.973889711267958, 57.70272356818387];

  const [isExpanded, setIsExpanded] = useState(false);
  const [isRestrictionNetworkCallActive, setIsRestrictionNetworkCallActive] =
    useState(false);

  // <editor-fold  description="restriction-mark">

  const [marker, setMarkerPosition] = useState({
    latitude: defaultCoordinate[1],
    longitude: defaultCoordinate[0],
  });

  const [showMarker, setMarkerState] = useState(false);

  const [events, logEvents] = useState({});

  const onMarkerDragStart = useCallback((event) => {
    logEvents((_events) => ({..._events, onDragStart: event.lngLat}));
  }, []);

  const onMarkerDrag = useCallback((event) => {
    logEvents((_events) => ({..._events, onDrag: event.lngLat}));
    setMarkerPosition({
      longitude: event.lngLat.lng,
      latitude: event.lngLat.lat,
    });
  }, []);

  const onMarkerDragEnd = useCallback((event) => {
    logEvents((_events) => ({..._events, onDragEnd: event.lngLat}));
    // console.log(`[${event.lngLat.lng}, ${event.lngLat.lat}]`)
  }, []);

  const onRender = useCallback(() => {
    const _mapRef = mapRef?.current
    if (!_mapRef) return

    // Saves map reference to state
    setMapReference(_mapRef)
  }, [])

  // </editor-fold  description="restriction-mark">

  const onMapShowInfo = (_info: string, publishType: InfoType) => {
    // const _infoList: string[] = Array.from<string>(infoList);
    // _infoList.push(_info);

    setInfoSliderProps({
      showSlider: true,
      info: _info,
      infoType: publishType === InfoType.SUCCESS ? 1 : 0,
    });

    const timeoutId = setTimeout(() => {
      // const items = infoList.filter((inf: string,) => inf !== _info);
      // if (infoList.length === 0) {
      //     return;
      // }

      // console.log(infoList)
      // infoList.shift();

      setInfoSliderProps({
        showSlider: false,
        info: '',
        infoType: -1,
      });
      clearTimeout(timeoutId);
    }, 3000);
  };

  // <editor-fold  description="restriction">
  const onCreateRestriction = async (restrictionDataObject) => {
    let updatedRestrictionData = {
      ...restrictionDataObject,
      createdBy: userState.email,
      coordinates: [marker.longitude, marker.latitude],
    };
    setIsRestrictionNetworkCallActive(true);
    const response = await addRestriction(updatedRestrictionData);
    if (response !== null) {
      const restriction = response.data;
      setIsRestrictionNetworkCallActive(false);
      setURestrictionData([...uRestrictionData, restriction]);
      showPositionMarker(false);
      onDrawerClose();
    }
  };
  const onUpdateRestriction = async (restrictionDataObject) => {
    let updatedRestrictionData = {
      ...restrictionDataObject,
      createdBy: userState.email,
      coordinates: [marker.longitude, marker.latitude],
    };
    setIsRestrictionNetworkCallActive(true);
    const response = await updateRemoteRestriction(updatedRestrictionData);
    if (response !== null) {
      setIsRestrictionNetworkCallActive(false);
      const otherRestrictions = uRestrictionData.filter(
        (restriction) => restriction.id !== updatedRestrictionData.id,
      );
      setURestrictionData([...otherRestrictions, updatedRestrictionData]);
      showPositionMarker(false);
      onDrawerClose();
    }
  };
  const onDeleteRestrictionFromNav = async (restriction) => {
    const response = await onDeleteRestriction(restriction);
    if(response !== null){
      onDrawerClose()
    }
  }
  const onDeleteRestriction = async (restriction) => {
    const response = await removeRestriction(restriction.id);
    if (response !== null) {
      const otherRestrictions = uRestrictionData.filter(
        (other) => other.id !== restriction.id,
      );
      setURestrictionData(otherRestrictions);
    }
    return response;
  };

  const onClearEditRestrictionData = () => {
    setRestrictionDataToEdit({});
  };

  const onEditRestrictionPage = (restriction) => {
    setActiveSubPage('addLimitation');
    setRestrictionDataToEdit(restriction);
  };

  const onEditRestrictionFromIcon = (restriction) => {
    setRestrictionDataToEdit(restriction);
    setDrawerOpen(true);
    setActiveSubPage('addLimitation');
    showPositionMarker(true);
  }

  // </editor-fold  description="restriction">

  const onDrawerClose = () => {
    setDrawerOpen(false);
    setActiveSubPage(null);
    showPositionMarker(false);
    onClearEditRestrictionData();
  };

  const onMoveToCoord = (coordinates, zoom = 16 ) => {
    setMarkerPosition({
      latitude: coordinates[1],
      longitude: coordinates[0],
    });

    mapRef.current.flyTo({
      center: [coordinates[0], coordinates[1]],
      zoom: zoom,
      essential: true,
    });
  };

  const onMoveMapToCoord = (coordinates, zoom = 16) => {
    mapRef.current.flyTo({
      center: [coordinates[0], coordinates[1]],
      zoom: zoom,
      essential: true,
    });
  };

  const showPositionMarker = (status) => {
    setMarkerState(status);
  };

  const toggleLayerVisibility = (selectedLayerIds) => {
    const map = mapRef.current.getMap();
    availableLayers.forEach((layer) => {
      const visibility = selectedLayerIds.includes(layer.id) ? 'visible' : 'none';

      if (layer.id !== 'feedpointsId' && layer.id !== 'stationId') {
        map.setLayoutProperty(layer.id, 'visibility', visibility);
      }

      if (layer.id === 'stationId') {
        setShowStations(visibility === 'visible');
        return;
      }

      if (layer.id === 'feedpointsId') {
        setShowFeedPoints(visibility === 'visible');
      }
    });
  };

  const _onUpdateSectionsByArray = (
    _sections: any[],
    action: MapComponentActionType,
    onUpdate: (arg0: any) => void,
    dataToUpdate: any,
  ) => {
    const newAction = action === MapComponentActionType.OPEN;
    let others = dataToUpdate;

    const _safeSections = _sections.flatMap((section) => {
      const safeSections = Array.from(section.safeSections).flatMap((safeSec) =>
        dataToUpdate.filter((sec: any) => sec.internal_id === safeSec),
      );
      others = others.filter((item: any) => item.internal_id !== section.internal_id);

      safeSections.forEach((safeSec: any) => {
        others = others.filter((item: any) => item.internal_id !== safeSec.internal_id);
      });
      return safeSections;
    });

    const mergedSections = removeDuplicates([..._safeSections, ..._sections]);
    const updatedSections = mergedSections.map((safeSec: any) => {
      return {
        ...safeSec,
        isOpen: newAction,
        isInSafeTrackMode: false,
      };
    });

    const sectionIds = updatedSections.map((sec) => sec.internal_id);
    const sectionChangeTracker = removeDuplicates(
      [...sectionIds, ...sectionChangeLog],
      DuplicateOperationType.IDs,
    );

    onUpdate([...updatedSections, ...others]);
    setSectionChangeLog(sectionChangeTracker);
  };

  const _onUpdateSection = (
    section: any,
    safeSections: any[],
    action: MapComponentActionType,
    onUpdate: (arg0: any) => void,
    dataToUpdate: any,
  ) => {
    const newAction = action === MapComponentActionType.OPEN;

    let others = dataToUpdate.filter((item) => item.internal_id !== section.internal_id);
    safeSections.forEach((safeSec: any) => {
      others = others.filter((item) => item.internal_id !== safeSec.internal_id);
    });

    const updateSafeSections = safeSections.map((safeSec: any) => {
      return {
        ...safeSec,
        isInSafeTrackMode: !newAction,
      };
    });

    const updatedSection = {
      ...section,
      isOpen: newAction,
      isInSafeTrackMode: false,
    };

    const safeSectionIds = updateSafeSections.map((sec) => sec.internal_id);
    const sectionChangeTracker = removeDuplicates(
      [...sectionChangeLog, ...safeSectionIds, updatedSection.internal_id],
      DuplicateOperationType.IDs,
    );

    onUpdate([updatedSection, ...updateSafeSections, ...others]);
    setSectionChangeLog(sectionChangeTracker);
  };

  const removeDuplicates = (
    arr: any[],
    operationType: DuplicateOperationType = DuplicateOperationType.TRACK_OBJECT,
  ) => {
    const ids: any[] = Array.from([]);
    const items: any[] = Array.from([]);

    if (operationType === DuplicateOperationType.IDs) {
      arr.forEach((item: any) => {
        if (ids.includes(item)) return;
        ids.push(item);
      });
      return ids;
    }

    arr.forEach((item: any) => {
      if (ids.includes(item.internal_id)) {
        return;
      }
      ids.push(item.internal_id);
      items.push(item);
    });
    return items;
  };

  const _pack = (sec: any, trackedIdArrays: string[]) => {
    let _safeSections = Array.from(sec.safeSections);
    trackedIdArrays.forEach((id) => {
      if (trackedIdArrays.includes(id)) {
        _safeSections = _safeSections.filter((_id) => _id !== id);
        return;
      }
      trackedIdArrays.push(sec.internal_id);
    });
    return {
      ...sec,
      safeSections: _safeSections,
    };
  };

  // @ts-ignore
  const _fetchClosedSafeSection = (
    section: any,
    globalSectionArray: any[],
    excludeId: string,
    trackedIdArrays: string[],
    positivePredicate: (item) => boolean,
    negativePredicate: (item) => boolean,
  ) => {
    if (section === undefined) return [];
    const safeSectionsIDs = Array.from(section.safeSections);
    if (safeSectionsIDs.length < 1) return [];
    const safeSections = safeSectionsIDs.flatMap((id) =>
      globalSectionArray.filter(
        (sec) => sec.internal_id !== excludeId && sec.internal_id === id,
      ),
    );
    if (safeSections.length < 1) return [];
    if (!trackedIdArrays.includes(section.internal_id)) {
      trackedIdArrays.push(section.internal_id);
    }

    const closeSafeSections = safeSections
      .filter(positivePredicate)
      .map((sec) => _pack(sec, trackedIdArrays));
    const sectionsThatAreNotOpen = safeSections.filter(negativePredicate).map((sec) => {
      if (sec.isInSafeTrackMode) return;
      return _pack(sec, trackedIdArrays);
    });

    const transversed: any[] = [...closeSafeSections, ...sectionsThatAreNotOpen].flatMap(
      (sec) =>
        _fetchClosedSafeSection(
          sec,
          globalSectionArray,
          section.internal_id,
          trackedIdArrays,
          positivePredicate,
          negativePredicate,
        ),
    );
    return removeDuplicates([...transversed, ...closeSafeSections]);
  };

  // @ts-ignore
  const deepSectionsSearch = (
    section: any,
    searchType: DeepSectionSearchType,
    globalSectionArray: any[],
    excludeId: string,
    trackedIdArrays: string[],
    positivePredicate: (item) => boolean,
    negativePredicate: (item) => boolean,
  ) => {
    const safeSectionsIDs = Array.from(section.safeSections);
    if (safeSectionsIDs.length < 1) return [];
    const safeSections = safeSectionsIDs.flatMap((id) =>
      globalSectionArray.filter(
        (sec) => sec.internal_id !== excludeId && sec.internal_id === id,
      ),
    );
    if (safeSections.length < 1) return [];

    if (searchType === DeepSectionSearchType.CLOSED_SECTIONS) {
      return _fetchClosedSafeSection(
        section,
        globalSectionArray,
        excludeId,
        trackedIdArrays,
        positivePredicate,
        negativePredicate,
      );
    }

    let safeTrackSections = safeSections.filter(positivePredicate).map((safeSec) => {
      trackedIdArrays.push(safeSec.internal_id);
      return safeSec;
    });
    let notSafeTrackSections = safeSections.filter(negativePredicate).map((safeSec) => {
      trackedIdArrays.push(safeSec.internal_id);
      return safeSec;
    });

    let reducedSafeSections = notSafeTrackSections.map((sec) =>
      _pack(sec, trackedIdArrays),
    );

    const transversed: any[] = reducedSafeSections.flatMap((sec) =>
      deepSectionsSearch(
        sec,
        searchType,
        globalSectionArray,
        section.internal_id,
        trackedIdArrays,
        positivePredicate,
        negativePredicate,
      ),
    );
    return [...transversed, ...safeTrackSections];
  };

  // @ts-ignore
  const fetchSafeSectionInSafeTrackMode = (
    section: any,
    globalSectionArray: any[],
    excludeId: string,
    trackedIdArrays: string[],
  ) => {
    const safeSectionsIDs = Array.from(section.safeSections);
    if (safeSectionsIDs.length < 1) return [];
    const safeSections = safeSectionsIDs.flatMap((id) =>
      globalSectionArray.filter(
        (sec) => sec.internal_id !== excludeId && sec.internal_id === id,
      ),
    );

    if (safeSections.length < 1) return [];
    let safeTrackSections = safeSections
      .filter((safeSec) => safeSec.isOpen && safeSec.isInSafeTrackMode)
      .map((safeSec) => {
        trackedIdArrays.push(safeSec.internal_id);
        return safeSec;
      });
    let notSafeTrackSections = safeSections
      .filter((safeSec) => !safeSec.isOpen && !safeSec.isInSafeTrackMode)
      .map((safeSec) => {
        trackedIdArrays.push(safeSec.internal_id);
        return safeSec;
      });

    let reducedSafeSections = notSafeTrackSections.map((sec) => {
      let _safeSections = Array.from(sec.safeSections);
      trackedIdArrays.forEach((id) => {
        if (trackedIdArrays.includes(id)) {
          _safeSections = _safeSections.filter((_id) => _id !== id);
          return;
        }
        trackedIdArrays.push(id);
      });
      return {
        ...sec,
        safeSections: _safeSections,
      };
    });

    const transversed: any[] = reducedSafeSections.flatMap((sec) =>
      fetchSafeSectionInSafeTrackMode(
        sec,
        globalSectionArray,
        section.internal_id,
        trackedIdArrays,
      ),
    );
    return [...safeTrackSections, ...transversed];
  };

  const _onUpdateStationsByArray = (
    _stations: any[],
    action: MapComponentActionType,
    onUpdate: (arg0: any) => void,
    dataToUpdate: any,
    isActionFromSection: boolean = false,
  ) => {
    const newAction = action === MapComponentActionType.OPEN;

    const cleanStations = removeDuplicates(_stations);
    let others = JSON.parse(JSON.stringify(dataToUpdate));
    cleanStations.forEach((station: any) => {
      others = others.filter((item) => item.internal_id !== station.internal_id);
    });

    const updateStations = cleanStations.map((station: any) => {
      return !isActionFromSection
        ? {
          ...station,
          isOpen: newAction,
        }
        : {
          ...station,
          isOpen: newAction,
          isConnectedSectionOpen: newAction,
        };
    });

    const stationIds = updateStations.map((stat) => stat.internal_id);
    const stationsChangeTracker = removeDuplicates(
      [...stationChangeLog, ...stationIds],
      DuplicateOperationType.IDs,
    );

    onUpdate([...updateStations, ...others]);
    setStationChangeLog(stationsChangeTracker);
  };

  const onUpdateSection = (
    section: any,
    _stations: any[],
    action: MapComponentActionType,
  ) => {
    let safeSections = Array.from(section.safeSections).flatMap((safeSec) =>
      sections.filter((sec) => sec.internal_id === safeSec),
    );

    if (section.isInSafeTrackMode && action === MapComponentActionType.CLOSE) {
      safeSections = safeSections.filter((safeSec) => safeSec.isOpen);
    }

    if (action === MapComponentActionType.OPEN) {
      const isAdjacentTrackClosed = safeSections.some((safeSec) => !safeSec.isOpen);
      if (isAdjacentTrackClosed) {
        const safeSectionCheckedIds: string[] = Array.from([section.internal_id]);
        const closeSectionCheckedIds: string[] = Array.from([section.internal_id]);
        const safeTracks = deepSectionsSearch(
          section,
          DeepSectionSearchType.SAFE_SECTIONS,
          sections,
          section.internal_id,
          safeSectionCheckedIds,
          (safeSec) => safeSec.isOpen && safeSec.isInSafeTrackMode,
          (safeSec) => !safeSec.isOpen && !safeSec.isInSafeTrackMode,
        );
        const closedTracks = deepSectionsSearch(
          section,
          DeepSectionSearchType.CLOSED_SECTIONS,
          sections,
          section.internal_id,
          closeSectionCheckedIds,
          (safeSec) => !safeSec.isOpen,
          (safeSec) => safeSec.isOpen,
        );

        const _safeSectionsStations = [...safeTracks, ...closedTracks].flatMap(
          (safeSec) => {
            const _feedPoints = feedPoints.filter(
              (fp) => fp.sections === safeSec.internal_id,
            );
            return _feedPoints.flatMap((fp) =>
              stations.filter((s) => s.internal_id === fp.stations),
            );
          },
        );
        const _cleanedStations = removeDuplicates(_safeSectionsStations);
        const __sections = [section, ...closedTracks];

        _onUpdateSectionsByArray(__sections, action, setSections, sections);
        _onUpdateStationsByArray(
          [..._cleanedStations, ..._stations],
          action,
          setStations,
          stations,
          true,
        );
        return;
      }
    }

    const safeSectionsStations = safeSections.flatMap((safeSec) => {
      const _feedPoints = feedPoints.filter((fp) => fp.sections === safeSec.internal_id);
      return _feedPoints.flatMap((fp) =>
        stations.filter((s) => s.internal_id === fp.stations),
      );
    });

    const __stations = [...safeSectionsStations, ..._stations];

    _onUpdateSection(section, safeSections, action, setSections, sections);
    _onUpdateStationsByArray(__stations, action, setStations, stations, true);
  };

  const _onUpdateStation = (
    station: any,
    action: MapComponentActionType,
    onUpdate: (arg0: any) => void,
    dataToUpdate: any,
    isActionFromSection: boolean = false,
  ) => {
    const newAction = action === MapComponentActionType.OPEN;

    const others = dataToUpdate.filter(
      (item) => item.internal_id !== station.internal_id,
    );
    const updatedStation = !isActionFromSection
      ? {
        ...station,
        isOpen: newAction,
      }
      : {
        ...station,
        isOpen: newAction,
        isConnectedSectionOpen: newAction,
      };

    const stationChangeTracker = removeDuplicates(
      [...stationChangeLog, updatedStation.internal_id],
      DuplicateOperationType.IDs,
    );

    onUpdate([updatedStation, ...others]);
    setStationChangeLog(stationChangeTracker);
  };

  const onUpdateStation = (station: any, action: MapComponentActionType) =>
    _onUpdateStation(station, action, setStations, stations);

  const _onUpdateFeedPoint = (
    feedPoint: IFeedPoint,
    action: MapComponentActionType,
    onUpdate: (arg0: any) => void,
    dataToUpdate: any,
  ) => {
    const newAction = action === MapComponentActionType.OPEN;

    const others = dataToUpdate.filter(
      (item) => item.internal_id !== feedPoint.internal_id,
    );
    const updatedFeedPoint = {
      ...feedPoint,
      isOpen: newAction,
    };
    const feedPointChangeTracker = removeDuplicates(
      [...feedPointsChangeLog, updatedFeedPoint.internal_id],
      DuplicateOperationType.IDs,
    );
    onUpdate([updatedFeedPoint, ...others]);

    setFeedPointsChangeLog(feedPointChangeTracker);
  };

  const onUpdateFeedPoint = (fp: IFeedPoint, action: MapComponentActionType) =>
    _onUpdateFeedPoint(fp, action, setFeedPoints, feedPoints);

  const detectChange = (
    localDataStore: any,
    feedPointsInState: any[],
    stationsInState: any[],
    sectionsInState: any[],
  ) => {
    if (
      feedPointsInState.length === 0 ||
      stationsInState.length === 0 ||
      sectionsInState.length === 0
    )
      return {isChangeDetected: false, payload: undefined};
    if (localDataStore === undefined) throw Error('Local Reference Store not found');

    let localStoreFeedPointRef = localDataStore['feedPoints'];
    let localStoreStationRef = localDataStore['stations'];
    let localStoreSectionsRef = localDataStore['sections'];
    const payload: any = {};

    const _feedPoints = feedPointsChangeLog
      .flatMap((id) => feedPointsInState.filter((sec) => sec.internal_id === id))
      .filter((fp) => {
        const refFeedPoint: any = localStoreFeedPointRef.filter((s) => s.internal_id === fp.internal_id)[0];
        refFeedPoint['comments'] =
          refFeedPoint.comments !== undefined && refFeedPoint.comments !== ''
            ? refFeedPoint.comments
            : '';
        return localDataStore !== undefined && !isEqual(fp, refFeedPoint);
      });
    const _stations = stationChangeLog
      .flatMap((id) => stationsInState.filter((stat) => stat.internal_id === id))
      .filter((stat) => {
        const refStation: any = localStoreStationRef.filter((s) => s.internal_id === stat.internal_id)[0];
        refStation['comments'] =
          refStation.comments !== undefined && refStation.comments !== ''
            ? refStation.comments
            : '';
        return localDataStore !== undefined && !isEqual(JSON.stringify(stat), JSON.stringify(refStation));
      });
    const _sections = sectionChangeLog
      .flatMap((id) => sectionsInState.filter((sec) => sec.internal_id === id))
      .filter((sec) => {
        const refSection: any = localStoreSectionsRef.filter((s) => s.internal_id === sec.internal_id)[0];
        refSection['comments'] =
          refSection.comments !== undefined && refSection.comments !== ''
            ? refSection.comments
            : '';
        return localDataStore !== undefined && !isEqual(sec, refSection);
      });

    payload['feedPoints'] = _feedPoints.length > 0 ? _feedPoints : [];
    payload['stations'] = _stations.length > 0 ? _stations : [];
    payload['sections'] = _sections.length > 0 ?_sections : [];

    const isChangeDetected: boolean =
      _feedPoints.length > 0 || _stations.length > 0 || _sections.length > 0;
    return {isChangeDetected, payload};
  };

  const _canShowPublishButton = () => {
    return detectChange(localDataStore, feedPoints, stations, sections).isChangeDetected;
  };
  const _publish = () => {
    const {isChangeDetected, payload} = detectChange(
      localDataStore,
      feedPoints,
      stations,
      sections,
    );
    if (isChangeDetected) {
      setPublishState(true);
      batchUpdateRemoteMapObjects(payload)
        .then((response) => {
          if (response.data) {
            let localStoreFeedPointRef = localDataStore['feedPoints'];
            let localStoreStationRef = localDataStore['stations'];
            let localStoreSectionsRef = localDataStore['sections'];

            const _fps = payload['feedPoints'] !== undefined ? payload['feedPoints'] : [];
            const _stats = payload['stations'] !== undefined ? payload['stations'] : [];
            const _secs = payload['sections'] !== undefined ? payload['sections'] : [];

            _fps.forEach((fp: any) => {
              localDataStore['feedPoints'] = localStoreFeedPointRef.map((item) => {
                if(item.internal_id === fp.internal_id) return fp;
                return item;
              })
            });

            _stats.forEach(
              (stat: any) => {
                localDataStore['stations'] = localStoreStationRef.map((item) => {
                  if(item.internal_id === stat.internal_id) return stat;
                  return item;
                })
              }
            );

            _secs.forEach((sec: any) => {
              localDataStore['sections'] = localStoreSectionsRef.map((item) => {
                if(item.internal_id === sec.internal_id) return sec;
                return item;
              })
            });

            setPublishState(false);
            onMapShowInfo(InfoText.PUBLISH_WAS_SUCCESSFUL, InfoType.SUCCESS);
            onClearLogs();
            return;
          }
          setPublishState(false);
          onMapShowInfo(response.msg, InfoType.FAILURE);
        })
        .catch((error) => {
          setPublishState(false);
          onMapShowInfo(InfoText.PUBLISH_WAS_NOT_SUCCESSFUL, InfoType.FAILURE);
        });
    }
  };

  const onClearLogs = () => {
    setSectionChangeLog([]);
    setStationChangeLog([]);
    setFeedPointsChangeLog([]);
  }

  useEffect(() => {
    if (mapReference) {
      mapReference.setLanguage('sv')
    }

    getMapData(false)
      .then((response) => {
        if (response) {
          const {restrictions,  feedPoints, stations, sections} = response.data;
          localDataStore = JSON.parse(JSON.stringify(response.data));

          const searchEntries = [
            ...feedPoints,
            ...stations,
          ];

          setURestrictionData(restrictions);
          setFeedPoints(feedPoints);
          setSections(sections);
          setStations(stations);
          setSearchEntries(searchEntries);
        }
      })
      .catch((e) => {
        console.log(`There was an error fetching the data: ${e}`);
      });
  }, [mapReference]);

  const renderSubPageContent = () => {
    if (!activeSubPage) return null;

    if (activeSubPage !== TRAFFIC_SIM_PAGE_NAME.ADD_LIMITATION && showMarker) {
      showPositionMarker(false);
    }

    switch (activeSubPage) {
      case TRAFFIC_SIM_PAGE_NAME.FEEDPOINTS:
        return (
          <Feedpoints
            mapRef={mapRef}
            feedPoints={feedPoints}
            stations={stations}
            sections={sections}
            onStationSaveState={onUpdateStation}
            onFeedPointSaveState={onUpdateFeedPoint}
          />
        );
      case TRAFFIC_SIM_PAGE_NAME.ADD_LIMITATION:
        return (
          <AddLimitation
            isNetworkCallActive={isRestrictionNetworkCallActive}
            onMoveToCoord={onMoveToCoord}
            showPositionMarker={showPositionMarker}
            clearEditData={onClearEditRestrictionData}
            onCloseDrawer={onDrawerClose}
            setActiveSubPage={setActiveSubPage}
            dataToEdit={dataToEdit}
            onDeleteRestriction={onDeleteRestrictionFromNav}
            onUpdateRestriction={onUpdateRestriction}
            onCreateRestriction={onCreateRestriction}
          />
        );
      case TRAFFIC_SIM_PAGE_NAME.RESERVE_FEEDPOINTS:
        return <ReserveFeedpoints/>;
      case TRAFFIC_SIM_PAGE_NAME.LIMITATIONS:
        return (
          <CurrentLimitations
            setActiveSubPage={onEditRestrictionPage}
            onMoveMapToCoord={onMoveMapToCoord}
            onDeleteRestriction={onDeleteRestriction}
            isDrawerOpen={drawerOpen}
          />
        );
      case TRAFFIC_SIM_PAGE_NAME.UPCOMING_LIMITATION:
        return (
          <UpcomingLimitations
            setActiveSubPage={onEditRestrictionPage}
            onMoveMapToCoord={onMoveMapToCoord}
            onDeleteRestriction={onDeleteRestriction}
            isDrawerOpen={drawerOpen}
          />
        );
      case TRAFFIC_SIM_PAGE_NAME.HISTORY:
        return <History/>;
      default:
        return null;
    }
  };

  const isDataLoaded =
    stations.length > 0 && feedPoints.length > 0 && sections.length > 0;

  return (
    <>
      {!isDataLoaded ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '40vh',
            justifyContent: 'center',
          }}
        >
          <CircularProgress/>
        </div>
      ) : (
        <Map
          id="trafficMap"
          initialViewState={{
            latitude: defaultCoordinate[1],
            longitude: defaultCoordinate[0],
            zoom: 14,
          }}
          cursor="pointer"
          mapStyle={mapStyle}
          mapboxAccessToken={MAPBOX_TOKEN}
          interactiveLayerIds={['section-layer-section01']}
          ref={mapRef}
          maxPitch={0}
          minPitch={0}
          minZoom={10}
          maxZoom={20}
          onRender={onRender}
          dragRotate={false}
          touchPitch={false}
        >
          <DrawerStyled
            anchor="right"
            open={drawerOpen}
            onClose={() => {
              onDrawerClose();
            }}
            title={t(activeSubPage)}
            currentPage={activeSubPage}
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
          >
            <Typography variant="subtitle1">
              {[TRAFFIC_SIM_PAGE_NAME.LIMITATIONS, TRAFFIC_SIM_PAGE_NAME.UPCOMING_LIMITATION].includes(activeSubPage) ? (
                activeSubPage === TRAFFIC_SIM_PAGE_NAME.LIMITATIONS ? (
                  <CurrentLimitations
                    setActiveSubPage={onEditRestrictionPage}
                    onMoveMapToCoord={onMoveMapToCoord}
                    onDeleteRestriction={onDeleteRestriction}
                    isExpanded={isExpanded}
                  />
                ) : (
                  <UpcomingLimitations
                    setActiveSubPage={onEditRestrictionPage}
                    onMoveMapToCoord={onMoveMapToCoord}
                    onDeleteRestriction={onDeleteRestriction}
                    isExpanded={isExpanded}
                  />
                )
              ) : (
                renderSubPageContent()
              )}
            </Typography>
          </DrawerStyled>
          {showMarker && (
            <Marker
              longitude={marker.longitude}
              latitude={marker.latitude}
              anchor="bottom"
              draggable
              onDragStart={onMarkerDragStart}
              onDrag={onMarkerDrag}
              onDragEnd={onMarkerDragEnd}
            >
              <RestrictionPin size={40}/>
              <Source
                  id={'location-marker'}
                  type="geojson"
                  data={{
                    type: 'FeatureCollection',
                    name: 'restrictions',
                    features: [{
                      type: 'Feature',
                      properties: {
                        type: 'CAnnanBegransning',
                      },
                      geometry: {
                        type: 'Point',
                        coordinates: [marker.longitude, marker.latitude],
                      },
                    }],
                  }}
              >
                <Layer
                    id={'layer-location-marker'}
                    type="symbol"
                    source={'location-marker'}
                    layout={{
                      'icon-image': ['get', 'type'],
                      'icon-allow-overlap': true,
                      'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        0,
                        0.3,
                        14,
                        0.3,
                        14.1,
                        0.2,
                        15,
                        0.3,
                        20,
                        0.5,
                      ]
                    }}
                />
              </Source>
            </Marker>
          )}

          <LayerSelector layers={availableLayers} onLayerToggle={toggleLayerVisibility}/>

          {_canShowPublishButton() && hasTrafficSimAdminAccess(userState) && (
            <Stack
              direction="row-reverse"
              position="fixed"
              color="primary"
              style={{
                margin: 8,
                marginRight: theme.spacing(1),
                marginTop: '40px',
                right: 0,
                float: 'right',
              }}
              spacing={1}
              aria-describedby={'publish-button'}
            >
              <LoadingButton
                sx={{
                  color: theme.palette.map.white,
                  ':hover': {
                    bgcolor: theme.palette.action.active,
                  },
                  backgroundColor: theme.palette.action.active,
                  borderColor: theme.palette.map.white,
                }}
                onClick={() => _publish()}
                disabled={isPublishing}
                loading={isPublishing}
                endIcon={<Save/>}
                loadingPosition="end"
                variant="outlined"
              >
                <span>Publish</span>
              </LoadingButton>
            </Stack>
          )}

          {sections &&
            sections.map((section) => {
              const _feedPoints = feedPoints.filter(
                (fp) => fp.sections === section.internal_id,
              );
              const _stations = _feedPoints.flatMap((fp) =>
                stations.filter((s) => s.internal_id === fp.stations),
              );

              return (
                <Section
                  key={section.id}
                  mapRef={mapRef}
                  section={section}
                  feedPointArray={_feedPoints}
                  stationsArray={_stations}
                  onUpdateSection={onUpdateSection}
                />
              );
            })}

          {showStations &&
            stations &&
            stations.map((station) => {
              const _feedPoints = station.feedpoints;
              if (Array.isArray(_feedPoints)) {
                const feedPointSections = _feedPoints
                  .flatMap((fid: string) =>
                    feedPoints.filter((fp) => fp.internal_id === fid),
                  )
                  .flatMap((feedPoint: IFeedPoint) =>
                    sections.filter((sts) => sts.internal_id === feedPoint.sections),
                  );

                return (
                  <Station
                    key={station.id}
                    mapRef={mapRef}
                    station={station}
                    feedPointSections={feedPointSections}
                    onUpdateStation={onUpdateStation}
                  />
                );
              }
            })}

          {showFeedPoints &&
            feedPoints &&
            feedPoints.map((_feedpoint) => {
              // @ts-ignore
              let station = stations.filter(
                (station) => station.internal_id === _feedpoint.stations,
              )[0];
              // @ts-ignore
              let section = sections.filter(
                (section) => section.internal_id === _feedpoint.sections,
              )[0];
              // @ts-ignore
              let groupFeedPointsBySection =
                feedPoints.sections === ''
                  ? []
                  : feedPoints.filter(
                    (fp) =>
                      fp.internal_id !== _feedpoint.internal_id &&
                      fp.sections === _feedpoint.sections,
                  );

              return (
                <FeedPoint
                  key={_feedpoint.id}
                  mapRef={mapRef}
                  // @ts-ignore
                  feedPoint={_feedpoint}
                  station={station}
                  section={section}
                  feedPointsInCommonSection={groupFeedPointsBySection}
                  onUpdateFeedPoint={onUpdateFeedPoint}
                />
              );
            })}

          <Source
            id="section-divider-geojson"
            type="geojson"
            data={collectionOfDividers[0]}
          >
            <Layer
              id="layer-section-divider-geojson"
              type="symbol"
              source="section-divider-geojson"
              layout={{
                'icon-image': ['get', 'divider_type'],
                'icon-rotate': ['get', 'rotation_angle'],
                'icon-allow-overlap': true,
                'icon-size': [
                  'interpolate',
                  ['linear'],
                  ['zoom'],
                  1,
                  0.01,
                  15,
                  0.2,
                  19.71,
                  2,
                  20,
                  3,
                  21.3,
                  7,
                  22,
                  10,
                ],
              }}
            />
          </Source>

          <Source id="geojsonData" type="geojson" data={sektionsisolatorerData}>
            <Layer
              id="multiLineStringLayer"
              type="line"
              source="geojsonData"
              filter={['==', '$type', 'LineString']}
              paint={{
                'line-color': [
                  'match',
                  ['get', 'Color'],
                  'red',
                  theme.palette.map.red,
                  'green',
                  theme.palette.map.green,
                  theme.palette.map.red,
                ],
                'line-width': 3,
              }}
            />
          </Source>

          <Source id="stationName" type="geojson" data={stationNameData}>
            <Layer
              id="station-name-layer"
              type="symbol"
              source="stationName"
              layout={{
                'icon-image': 'train-icon',
                'icon-size': [
                  'interpolate',
                  ['linear'],
                  ['zoom'],
                  0,
                  0,
                  14,
                  0,
                  14.1,
                  0.2,
                  15,
                  0.2,
                  20,
                  0.3,
                ],
                'icon-anchor': 'top',

                'text-field': '{name}',
                'text-size': 10,
                'text-font': ['Roboto Medium'],
                'text-anchor': 'top',
                'text-offset': [0, -0.9],
              }}
              paint={{
                'text-color': theme.palette.map.grey,
              }}
            />
          </Source>

          <Source id="vaxlar" type="geojson" data={vaxlarData}>
            <Layer
              id="vaxlar-layer"
              type="symbol"
              source="vaxlar"
              layout={{
                'text-field': '{Namn}',
                'text-size': [
                  'interpolate',
                  ['linear'],
                  ['zoom'],
                  0,
                  5,
                  14,
                  ['*', 2, 6],
                  17,
                  ['*', 3, 7],
                  22,
                  ['*', 5, 7],
                ],
                'text-font': ['Roboto Bold'],
              }}
              paint={{
                'text-color': themeName === "dark" ? theme.palette.map.white : theme.palette.map.grey,
              }}
            />
          </Source>

          {uRestrictionData && uRestrictionData.map((restriction) => {
            return <Restriction key={restriction.id} mapRef={mapRef} restriction={restriction} onEditRestriction={onEditRestrictionFromIcon} />
          })}

          {info !== '' && (
            <Grid
              container
              direction="column"
              justifyContent="end"
              alignItems="end"
              position="fixed"
              color="primary"
              style={{top: 'auto', margin: 8, right: 0, bottom: 40}}
              spacing={1}
              aria-describedby={'version-number'}
            >
              <Grid key={`${Math.random() * 10000}`} item xs="auto">
                <Slide direction="left" in={true} mountOnEnter unmountOnExit>
                  <Box
                    sx={{
                      color: theme.palette.map.white,
                      ':hover': {
                        bgcolor: theme.palette.action.active,
                      },
                      backgroundColor:
                        infoType === 1 ? theme.palette.action.active : 'red',
                      borderColor: theme.palette.map.white,
                      p: 1,
                      m: 1,
                      borderRadius: 1,
                    }}
                  >
                    <Typography variant={'subtitle1'}>{info}</Typography>
                  </Box>
                </Slide>
              </Grid>
            </Grid>
          )}

          <Stack
            direction="row"
            position="fixed"
            color="primary"
            style={{top: 'auto', margin: 8, right: 0, bottom: 20}}
            spacing={1}
            aria-describedby={'version-number'}
          >
            <Typography
              variant="body1"
              style={{
                fontWeight: 'bold',
                color: theme.palette.text.primary,
              }}
            >
              v{appVersion}
            </Typography>
          </Stack>
        </Map>
      )}
    </>
  );
}
