export enum PeriodEnum {
  today = 'today',
  week = 'week',
  month = 'month',
}

export interface Periods {
  Today: PeriodEnum;
  Week: PeriodEnum;
  Month: PeriodEnum;
}

export const periodLabelToEnum: Periods = {
  Today: PeriodEnum.today,
  Week: PeriodEnum.week,
  Month: PeriodEnum.month,
} as Periods;
