import {TFunction} from 'i18next';
import {AlertTypeConfig} from 'types/alerts/alertTypeConfig';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {AlertTypeKey, AlertTypeLabels, AlertTypeShortLabels} from 'types/alerts/alertTypeKey.ts';
import {
  rideComfortAlertLevelToLabel,
  RideComfortAttributeAlertSValue,
  rideComfortLevelToAttribute
} from 'types/alerts/rideComfort/rideComfortAlertLevel.ts';
import {rideComfortErrorLevelConfigs} from 'types/alerts/rideComfort/rideComfortErrorLevelConfigs.ts';
import {rideComfortDataThresholds} from 'types/alerts/rideComfort/rideComfortDataThresholds.ts';
import {RideComfortAttributeAlertLevel} from 'types/alerts/rideComfort/rideComfortAttributeAlertLevel.ts';

export const rideComfortRoughRideTrainConfig = (t: TFunction): AlertTypeConfig => {
  return clsOrType<AlertTypeConfig>(CemitTypename.alertTypeConfig, {
    alertTypeKey: AlertTypeKey.roughRideTrainPointId,
    // use RideComfortAlertConfig alert type for the denominator of percent totals of alerts of
    // rideComfortRoughRideTrainConfig, due to an error in summing rideComfortRoughRideTrain on the backend
    // TODO disabled for now
    parentAlertTypeConfig: undefined, // rideComfortAlertConfig(t),
    alertLevelToAttribute: rideComfortLevelToAttribute,
    alertLevelToLabel: rideComfortAlertLevelToLabel(t),
    label: t(AlertTypeLabels.roughtRideTrainPointId),
    labelShort: t(AlertTypeShortLabels.trainPointId),
    attributeAlertLevelConfigs: rideComfortErrorLevelConfigs(t),
    criticalSValueLevel: RideComfortAttributeAlertSValue.critical,
    dataThresholds: rideComfortDataThresholds(t),
    warningLevels: [RideComfortAttributeAlertLevel.warning],
    criticalLevels: [RideComfortAttributeAlertLevel.critical],
    attributeAlertLevelEnum: RideComfortAttributeAlertLevel,
    defaultVisibleAttributeAlertLevels: [
      RideComfortAttributeAlertLevel.warning,
      RideComfortAttributeAlertLevel.critical,
    ],

    propsResolver: (node) => {
      return {};
    },
    unit: 'GENERIC',
    levelAllValueGreaterThan: 0.5,
  });
};
