import {AlertHeatMapData, AlertHeatMapDatum} from 'types/alerts/alertHeatMapDatum';
import {
  AlertEdge,
  GraphqlResponseAlertData,
  AlertNodeData,
  AlertNodeMeta, GraphqlResponseDateIntervalOverviewAlertData
} from 'types/alerts/alertMapData';
import {any, mergeRight, zipWith, length, filter, concat, includes} from 'ramda';
import {format} from 'date-fns';
import {Feature, Point} from 'geojson';
import {CemitTypename} from 'types/cemitTypename.ts';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {trainDataFriendlyDatetimeFormatWithTimezoneString} from 'utils/datetime/timeUtils.ts';
import {AlertScopeProps} from 'types/alerts/alertScopeProps';

import {AlertTrainGroupProps} from 'types/alerts/alertTrainGroupProps.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {TrainGroup} from 'types/trainGroups/trainGroup';
import {AlertBaseGauge} from 'types/alerts/alertGauge';
import {CrudList} from 'types/crud/crudList';
import {LoadingStatusEnum} from 'types/apis/loadingStatusEnum.ts';
import {useNotLoadingMemo} from 'utils/hooks/useMemoHooks.ts';
import {omitSensorBasedDataExceptProps} from 'appUtils/trainAppUtils/trainAppInterfaceUtils/trainGroupUtil.ts';
import {alertIncomingOrExistingStatusIsIncomplete} from 'appUtils/alertUtils/alertScopePropsUtils.ts';
import {useMemo} from 'react';
import {AttributeAlertLevelConfig} from 'types/alerts/attributeAlertLevelConfig';
import {AlertTypeConfig} from 'types/alerts/alertTypeConfig';
import {AlertGaugeByTimePeriod} from 'types/alerts/alertGaugeByTimePeriod.ts';
import {AlertOverviewByTimePeriod} from 'types/alerts/alertOverviewByTimePeriod.ts';

/**
 * @param alertScopeProps
 * @param alertGaugeByTimePeriod The new alertGaugeByTimePeriod. This is used
 * instead of the stale value in alertScopeProps
 * @param alertGraphqlResponseAlertData
 * @param trainGroupCrudList
 */
export const setTrainGroupAlertHeatMapWithQueryData = <T extends TrainGroup>(
  alertScopeProps: AlertScopeProps<T>,
  alertGaugeByTimePeriod: AlertGaugeByTimePeriod,
  alertGraphqlResponseAlertData: Perhaps<GraphqlResponseAlertData>,
  trainGroupCrudList: CrudList<TrainGroup>,
) => {
  const numericValues: number[] = [];
  const points: Feature<Point>[] = [];

  const array = Object?.entries(
    alertGraphqlResponseAlertData?.data?.levelAll?.edges || [],
  );

  array?.forEach((element: [string, AlertEdge]) => {
    // get s-values and numeric value
    const node: AlertNodeMeta = element[1].node;
    const nodeData: AlertNodeData = node.data;
    const value = parseFloat(nodeData.rawValue);
    const date = new Date(node.timestamp);
    const feature: Feature<Point> = node.location
      ? {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [
              element?.[1]?.node?.location?.lon,
              element?.[1]?.node?.location?.lat,
            ],
          },
          properties: {
            value,
            // TODO can we just make this a date and format later?
            timestamp: format(date, trainDataFriendlyDatetimeFormatWithTimezoneString),
            timestampAsDate: date,
            type: node.type,
            // TODO Fix
            ...alertScopeProps.alertConfigProps.alertTypeConfig.propsResolver(node),
          },
        }
      : undefined;
    if (value !== undefined) {
      numericValues.push(value);
      points.push(feature);
    }
  });

  const zipped: AlertHeatMapDatum[] = zipWith(
    (value: number, point: Feature<Point>) => {
      return clsOrType<AlertHeatMapDatum>(CemitTypename.alertHeatMapDatum, {
        numericValue: value,
        point,
      });
    },
    numericValues,
    points,
  );

  const heatMapData = clsOrType<AlertHeatMapData>(CemitTypename.alertHeatMapData, {
    data: zipped,
    trainRuntGroup: alertScopeProps.scopedTrainGroup,
  });

  const modifiedTrainGroup: TrainGroup = clsOrType<T>(
    alertScopeProps.scopedTrainGroup.__typename,
    // @ts-ignore Typescript should be able to see that this is okay
    mergeRight<T, Partial<T>>(alertScopeProps.scopedTrainGroup, {
      alertScopeProps: clsOrType<AlertScopeProps>(CemitTypename.alertScopeProps, {
        // Update the AlertConfigProps so we know what configuration the returned API data represents
        alertConfigProps: alertScopeProps.alertConfigProps,
        // Merge alertScopeProps.alertTrainGroupProps with the downloaded data:
        // The heatMapData, alertGaugeByTimePeriod are the derived data from the API call
        alertTrainGroupProps: clsOrType<AlertTrainGroupProps>(
          CemitTypename.alertReadOnlyTrainGroupProps,
          mergeRight(alertScopeProps.alertTrainGroupProps, {
            heatMapData,
            alertGaugeByTimePeriod,
            alertGraphqlStatus: LoadingStatusEnum.complete,
          }),
        ),
      }),
    }),
  );
  const omitted = omitSensorBasedDataExceptProps(['alertScopeProps'], modifiedTrainGroup);
  trainGroupCrudList.updateOrCreate(omitted);
};

/**
 * @param alertScopeProps
 * @param alertGaugesByTimePeriod An AlertGaugeByTimePeriod for today, week, and month
 * @param overviewAlertData An list of GraphqlResponseDateIntervalOverviewAlertData for today, 30 days, 90 days, and 180 days
 * @param trainGroupCrudList
 */
export const setTrainGroupAlertSummariesWithQueryData = <T extends TrainGroup>(
  alertScopeProps: AlertScopeProps<T>,
  alertGaugesByTimePeriod: AlertGaugeByTimePeriod[],
  overviewAlertData:  GraphqlResponseDateIntervalOverviewAlertData[],
  trainGroupCrudList: CrudList<TrainGroup>,
) => {
  const modifiedTrainGroup: TrainGroup = clsOrType<T>(
    alertScopeProps.scopedTrainGroup.__typename,
    // @ts-ignore Typescript should be able to see that this is okay
    mergeRight<T, Partial<T>>(alertScopeProps.scopedTrainGroup, {
      alertScopeSummaryProps: clsOrType<AlertScopeProps>(CemitTypename.alertScopeProps, {
        // Update the AlertConfigProps so we know what configuration the returned API data represents
        alertConfigProps: alertScopeProps.alertConfigProps,
        // Merge alertScopeProps.alertTrainGroupProps with the downloaded data:
        // The heatMapData, alertGaugeByTimePeriod are the derived data from the API call
        alertTrainGroupProps: clsOrType<AlertTrainGroupProps>(
          CemitTypename.alertReadOnlyTrainGroupProps,
          mergeRight(alertScopeProps.alertTrainGroupProps, {
            alertGaugesByTimePeriod,
            overviewAlertData: overviewAlertData,
            alertGraphqlStatus: LoadingStatusEnum.complete,
          }),
        ),
      }),
    }),
  );
  const omitted = omitSensorBasedDataExceptProps(
    ['alertScopeSummaryProps'],
    modifiedTrainGroup,
  );
  trainGroupCrudList.updateOrCreate(omitted);
};

/**
 * Returns true if for any AlertScopeProps either the incoming value alertScopeProps.alertTrainGroupProps.alertGraphqlStatus
 * or existing value alertScopeProps.scopedTrainGroup.alertScopeProps?.alertTrainGroupProps.alertGraphqlStatus
 * is not LoadingStatusEnum.complete
 * @param loading
 * @param alertScopePropsets
 */
export const useNotLoadingAnyAlertIncomingOrExistingStatusIsIncomplete = (
  loading: boolean,
  alertScopePropSets: AlertScopeProps[],
) => {
  return useNotLoadingMemo(
    loading,
    (alertScopePropSets: AlertScopeProps[]): boolean => {
      const result = any((alertScopeProps: AlertScopeProps) => {
        return alertIncomingOrExistingStatusIsIncomplete(alertScopeProps);
      }, alertScopePropSets);
      return result
    },
    [alertScopePropSets] as const,
  );
};

/**
 * Check the trainGroup's alertScopeSummaryProps to see if there are any non normal alert
 * @param trainGroup
 */
export const useMemoAnyNonNormalAlerts = (trainGroup: TrainGroup) => {
  return useMemo(() => {
    const alertGaugesByTimePeriod: Perhaps<AlertGaugeByTimePeriod[]> =
      trainGroup.alertScopeSummaryProps?.alertTrainGroupProps?.alertGaugesByTimePeriod;
    if (!length(alertGaugesByTimePeriod)) {
      return false;
    }
    // Check if any non-normal attributeAlertLevel has a value over 0
    return any<AlertGaugeByTimePeriod>(
      (alertGaugeByTimePeriod: AlertGaugeByTimePeriod) => {
        const alertGaugeToday: AlertBaseGauge = alertGaugeByTimePeriod.today;
        const alertTypeConfig: AlertTypeConfig = alertGaugeToday.alertTypeConfig!;
        const nonNormalLevels = concat(
          alertTypeConfig.warningLevels,
          alertTypeConfig.criticalLevels,
        );
        return any(
          (attributeAlertLevelConfig: AttributeAlertLevelConfig) => {
            return (
              alertGaugeToday[attributeAlertLevelConfig.attributeAlertLevel].value > 0
            );
          },
          filter((attributeAlertLevelConfig: AttributeAlertLevelConfig) => {
            return includes(
              attributeAlertLevelConfig.attributeAlertLevel,
              nonNormalLevels,
            );
          }, alertTypeConfig.attributeAlertLevelConfigs),
        );
      },
      alertGaugesByTimePeriod!,
    );
  }, [trainGroup.alertScopeSummaryProps?.alertTrainGroupProps]);
};
