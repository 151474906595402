import React, {useContext, useEffect, useState} from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableRow, Tooltip,
  Typography,
} from '@mui/material';
import {useTheme} from '@mui/styles';
import AvstangtSpar from 'assets/dataUrlIcons/trafficSimAssets/icons/AvstangtSpar.svg';
import Backforbud from 'assets/dataUrlIcons/trafficSimAssets/icons/Backforbud.svg';
import AnnanBegransning from 'assets/dataUrlIcons/trafficSimAssets/icons/AnnanBegransning.svg';
import Hastighetsbegraansning from 'assets/dataUrlIcons/trafficSimAssets/icons/Hastighetsbegraansning.svg';
import {ExpandLess, ExpandMore, FileDownload} from '@mui/icons-material';
import {getCurrentRestrictions} from 'pages/trafficsim/api/net/repo.ts';
import {restrictionTypes} from 'pages/trafficsim/api/data/consts.ts';
import EditButton
  from 'pages/trafficsim/trafficSimComponents/themedComponents/buttons/SpecificButtons/EditButton/EditButton.tsx';
import DeleteButton
  from 'pages/trafficsim/trafficSimComponents/themedComponents/buttons/SpecificButtons/DeleteButton/DeleteButton.tsx';
import {StyledTableCell} from 'pages/trafficsim/trafficSimComponents/themedComponents/CustomTable.tsx';
import DeleteConfirmationDialog
  from 'pages/trafficsim/trafficSimComponents/themedComponents/DeleteConfirmationDialog.tsx';
import ZoomLocationButton
  from 'pages/trafficsim/trafficSimComponents/themedComponents/buttons/IconsButtons/ZoomLocationButton.tsx';
import {ACCESS_LEVELS} from 'pages/trafficsim/utils/access_levels.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {UserContext, UserContextType} from 'pages/auth/UserContext.ts';
import {UserStateLoaded} from 'types/userState/userState';
import {
  extractTrafficSimAccess,
  hasTrafficSimAdminAccess
} from "../../../../appUtils/authentication/authenticationUtils.ts";
import {extractDateObject} from "../track/utils/datetime_util.ts";
import FirstPageRoundedIcon from "@mui/icons-material/FirstPageRounded";
import LastPageRoundedIcon from "@mui/icons-material/LastPageRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import {CustomTablePagination} from "../pagination/CustomTablePagination.tsx";
import {getComparator, Order, tableHeaderObj} from "../restrictionTableHeader/tableUtils.ts";
import {SharedTableHeader} from "../restrictionTableHeader/SharedTableHeader.tsx";
import {convertToExcel} from "../track/utils/functions.ts";

const CurrentLimitations = ({
                              setActiveSubPage = (restriction): any => {
                              },
                              onMoveMapToCoord = (coordinates): any => {
                              },
                              onDeleteRestriction = (currentRestriction): any => {
                              },
                              isExpanded,
                            }) => {


  const theme = useTheme();
  const userState = useContext<Perhaps<UserContextType>>(UserContext)!
    .userState as UserStateLoaded;

  const [pages, setPages] = useState<number>(0);
  const [foldedPageNumber, setFoldedPageNumber] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [filter, setFilter] = useState('');
  const [restrictions, setRestrictions] = useState([]);
  const [isLoading, setIsLoadingState] = useState(true);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [currentRestriction, setCurrentRestriction] = useState(null);
  const [orderBy, setOrderBy] = useState(tableHeaderObj.restriction.key);
  const [order, setOrder] = useState<Order>("desc");

  const downloadHistory = () => {
    const xlxsData = restrictions.map(data => {
      return {
        "Berörda": data.restrictionType,
        "start Plas": data.startPlace.name,
        "Slut Plas": data.endPlace.name,
        "Berörda Växlar": data.switchId,
        "Hastighet": data.speedLimit,
        "Orsak": data.creationReason,
        "Start Tid": data.startTimestamp,
        "Slut Tid": data.endTimestamp,
        "Notering": data.notes,
        "Begäran Av": data.authorizedBy,
        "Inlagd Av": data.createdBy,
      }
    });

    convertToExcel(xlxsData);
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPages(newPage);
    fetchRestriction(false, newPage + 1, rowsPerPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const newRows = parseInt(event.target.value, 10)
    if (newRows) {
      setRowsPerPage(newRows);
      setPages(0);
      setRestrictions([]);
      fetchRestriction(false, 1, newRows);
    }
  };

  const handleDeleteClick = (restriction) => {
    setCurrentRestriction(restriction);
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleConfirmDelete = async () => {
    const response = await onDeleteRestriction(currentRestriction);
    if (response) {
      const otherRestrictions = restrictions.filter(
          (other) => other.id !== currentRestriction.id,
      );
      setRestrictions(otherRestrictions);
      setIsLoadingState(true);
    }
    setDialogOpen(false);
  };

  const fetchRestriction = (isInit = false, _page, _rowsPerPage) => {
    if (isInit && isLoading) return;
    getCurrentRestrictions(_page, _rowsPerPage).then((response) => {
      if (response !== null) {
        setIsLoadingState(false);
        setTotal(response.total);
        if (!isExpanded) {
          setFoldedPageNumber((prevState) => prevState + 1)
          setRestrictions((prev) => [...response.data]);
          return;
        }
        setRestrictions(response.data);
      }
    });
  }

  useEffect(() => {
    if(isExpanded) {
      setFoldedPageNumber(0);
      setRowsPerPage(50);
      return;
    }
    fetchRestriction(false, foldedPageNumber + 1, rowsPerPage)
  }, [isExpanded]);

  const icons = {
    closedTrack: AvstangtSpar,
    backProhibition: Backforbud,
    speedLimit: Hastighetsbegraansning,
    other: AnnanBegransning,
  };

  const getIcon = (begransning) => {
    const IconSrc = icons[begransning];
    return IconSrc ? (
      <img src={IconSrc} alt={begransning} style={{marginRight: '8px'}}/>
    ) : null;
  };

  const editRestriction = async (restriction) => {
    setActiveSubPage(restriction);
  };

  const isNotReadOnlyAccess = () => {
    return (hasTrafficSimAdminAccess(userState) || extractTrafficSimAccess(userState) !== ACCESS_LEVELS.READ_ONLY);
  };


  const Row = ({row}) => {
    const [open, setOpen] = useState(false);

    const startTimestampDate = extractDateObject(row.startTimestamp);
    const endTimestampDate = extractDateObject(row.endTimestamp ?? "");

    return (
      <React.Fragment>
        <TableRow
          sx={{
            'td, th': { color: theme.palette.text.primary} ,
            '& > *': {
              borderBottom: (theme) =>
                open ? 'unset' : `2px solid ${theme.palette.border.greyLight}`,
            },
          }}
        >
          <TableCell>
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {getIcon(row.restrictionType)}
              {restrictionTypes[row.restrictionType]}
            </span>
          </TableCell>
          <TableCell>{`${row.startPlace.name} ${row.endPlace.name}`}</TableCell>
          <TableCell>{row.switchId}</TableCell>
          <TableCell>{row.speedLimit}</TableCell>
          <TableCell>{row.creationReason}</TableCell>
          <TableCell>
            {`${startTimestampDate.toDateString()} ${startTimestampDate.toTimeString().substring(0, 8)}`}
          </TableCell>
          <TableCell>{`${row.endTimestamp ? endTimestampDate.toDateString() : ""} ${row.endTimestamp ? endTimestampDate.toTimeString().substring(0, 8) : ""}`}</TableCell>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <ExpandLess/> : <ExpandMore/>}
            </IconButton>
          </TableCell>
          <TableCell>
            {isNotReadOnlyAccess() && (
              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                gap="2px"
                marginTop="5px"
              >
                <EditButton onEdit={() => editRestriction(row)}/>
                <DeleteButton onDelete={() => handleDeleteClick(row)}/>
                <DeleteConfirmationDialog
                  open={isDialogOpen}
                  onClose={handleClose}
                  onConfirm={handleConfirmDelete}
                />
              </Box>
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            style={{paddingBottom: 0, paddingTop: 0}}
            sx={{
              textAlign: 'left',
              borderBottom: open
                ? `2px solid ${theme.palette.border.greyLight}`
                : 'unset',
            }}
            colSpan={10}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{margin: 1}}>
                <Typography
                  variant="body2"
                  gutterBottom
                  component="span"
                  sx={{marginRight: '20px'}}
                >
                  <Box component="span" fontWeight="medium">
                    Notering:
                  </Box>{' '}
                  {row.notes}
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  component="span"
                  sx={{marginRight: '20px'}}
                >
                  <Box component="span" fontWeight="medium">
                    Begränsning på begäran av:
                  </Box>{' '}
                  {row.authorizedBy}
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  component="span"
                  sx={{marginRight: '20px'}}
                >
                  <Box component="span" fontWeight="medium">
                    Begränsning inlagd av:
                  </Box>{' '}
                  {row.createdBy}
                </Typography>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filterDropdown = (
    <FormControl
      sx={{m: 0, minWidth: 260, marginBottom: 3, fontSize: '12px'}}
      size="small"
    >
      <InputLabel id="filter-label" sx={{fontSize: '14px'}}>
        Filter
      </InputLabel>
      <Select
        style={{width: '200px'}}
        labelId="filter-label"
        id="filter-select"
        value={filter}
        label="Filter"
        onChange={handleFilterChange}
        sx={{fontSize: '12px'}}
      >
        <MenuItem value="" sx={{fontSize: '12px'}}>
          Alla
        </MenuItem>
        <MenuItem value="Avstängt spår" sx={{fontSize: '12px'}}>
          Avstängt spår
        </MenuItem>
        <MenuItem value="Backförbud" sx={{fontSize: '12px'}}>
          Backförbud
        </MenuItem>
        <MenuItem value="Hastighetsbegränsning" sx={{fontSize: '12px'}}>
          Hastighetsbegränsning
        </MenuItem>
        <MenuItem value="Annan begränsning" sx={{fontSize: '12px'}}>
          Annan begränsning
        </MenuItem>
      </Select>
    </FormControl>
  );

  const drawerLayout = (
    <div style={{width: '280px'}}>
      <Grid container spacing={2}>
        {restrictions.map((item, index) => {
            const startTimestampDate = extractDateObject(item.startTimestamp);
            const endTimestampDate = extractDateObject(item.endTimestamp ?? "");

            return <Grid item xs={12} key={index}>
              <Card variant="outlined">
                <CardContent style={{display: 'flex', flexDirection: 'column'}}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography
                      variant="h7"
                      style={{display: 'flex', alignItems: 'center'}}
                    >
                      {getIcon(item.restrictionType)}{' '}
                      {restrictionTypes[item.restrictionType]}
                    </Typography>
                    <span>
                    <ZoomLocationButton
                      onClick={() => onMoveMapToCoord(item.coordinates)}
                    />
                  </span>
                  </div>
                  <Typography variant="caption" color="text.secondary">
                    <strong>Plats:</strong> {item.startPlace.name} - {item.endPlace.name}{' '}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    <strong>Berörda:</strong>
                    {item.switchId}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    <strong>Hastighet:</strong> {item.speedLimit}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    <strong>Orsak:</strong> {item.creationReason}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    <strong>Starttid:</strong>{' '}
                    {`${startTimestampDate.toDateString()} ${startTimestampDate.toTimeString().substring(0, 8)}`}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    <strong>Sluttid:</strong>{' '}
                    {`${item.endTimestamp ? endTimestampDate.toDateString() : ""} ${item.endTimestamp ? endTimestampDate.toTimeString().substring(0, 8) : ""}`}
                  </Typography>

                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                    <Typography variant="caption" color="text.secondary">
                      <strong>Inlagd av:</strong>
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {item.createdBy}
                    </Typography>
                  </div>

                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                    <Typography variant="caption" color="text.secondary">
                      <strong>Begärd av:</strong>
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {item.authorizedBy}
                    </Typography>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                    <Typography variant="caption" color="text.secondary">
                      <strong>Notering:</strong>
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {item.notes}
                    </Typography>
                  </div>

                  {isNotReadOnlyAccess() && (
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="center"
                      gap="2px"
                      marginTop="5px"
                    >
                      <EditButton onEdit={() => editRestriction(item)}/>
                      <DeleteButton onDelete={() => handleDeleteClick(item)}/>
                      <DeleteConfirmationDialog
                        open={isDialogOpen}
                        onClose={handleClose}
                        onConfirm={handleConfirmDelete}
                      />
                    </Box>
                  )}
                </CardContent>
              </Card>
            </Grid>
          }
        )}
        {isLoading && (
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <CircularProgress/>
          </Grid>
        )}
        {total && total > restrictions.length &&
          <Grid sx={{mt: 2}} colSpan={8}>
            {!isLoading &&
              <Button onClick={() => fetchRestriction(true, foldedPageNumber + 1, rowsPerPage)}>Load More</Button>}
          </Grid>
        }
      </Grid>
    </div>
  );

  const handleRequestSort = (
      event: React.MouseEvent<unknown>,
      property: string,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const visibleRows = React.useMemo(
      () =>
          [...restrictions]
              .sort(getComparator(order, orderBy)),
      [order, restrictions, orderBy],
  );

  const createSortHandler =
      (property: string) => (event: React.MouseEvent<unknown>) => {
        handleRequestSort(event, property);
      };

  const expandedLayout = (
    <TableContainer component={Paper}>
      <Table sx={{minWidth: 650}} size="small" aria-label="collapsible table">
        <SharedTableHeader createSortHandler={createSortHandler} orderBy={orderBy} order={order} />
        <TableBody>
          {visibleRows.map((row, index) => (
            (row && <Row key={index} row={row}/>)
          ))}
          {isLoading && (
            <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
              <StyledTableCell>
                <CircularProgress/>
              </StyledTableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <CustomTablePagination
              rowsPerPageOptions={[50, 100, 150]}
              colSpan={9}
              count={total}
              rowsPerPage={rowsPerPage}
              page={pages}
              slotProps={{
                select: {
                  'aria-label': 'Rows',
                },
                actions: {
                  showFirstButton: true,
                  showLastButton: true,
                  slots: {
                    firstPageIcon: FirstPageRoundedIcon,
                    lastPageIcon: LastPageRoundedIcon,
                    nextPageIcon: ChevronRightRoundedIcon,
                    backPageIcon: ChevronLeftRoundedIcon,
                  },
                },
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );

  return (
    <div>
      {/*{filterDropdown}*/}
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        spacing={1}
        style={{width: '100%', margin: 0}}
      >
        <Grid item>
          <Tooltip title="Export to Excel">
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              style={{ marginBottom: '10px'}}
              onClick={downloadHistory}
              startIcon={<FileDownload />}
            >
              Export to Excel
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
      {isExpanded ? expandedLayout : drawerLayout}
    </div>
  );
};

export default CurrentLimitations;
