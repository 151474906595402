// TrainContainer.tsx
import React, {useState} from 'react';
import {
  Box,
  Chip,
  Grid,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import TrainFilters from './Filters.tsx';
import SandboxTrainDetail from 'pages/sandbox/SandboxTrainDetail.tsx';
import SearchBar from './SearchBar.tsx';
import SandBoxCard from './SandBoxCard.tsx';

import {TrainAndSandBox, TrainData} from './types/index.ts';
import SandboxTrainListTable from 'pages/sandbox/SandboxTrainListTable.tsx';
import SandboxHeaderBar from 'pages/sandbox/SandboxHeaderBar.tsx';
import {CemitAppOrganizationDependencyProps} from 'types/propTypes/appPropTypes/cemitAppPropTypes/cemitAppOrganizationDependencyProps.ts';

const generateTrainData = (): TrainData[] => [
  {
    status: '',
    sandboxes: [],
    fleet: 450,
    trainId: 450011,
    sandboxIds: process.env.REACT_SANDBOX_EXTERNAL_KEY!.split(','),
    travellingFrom: 'BEDHAMPTON',
    travellingTo: 'HAVANT',
    estimatedArrival: '2024-07-17T12:00:00Z', // Example ETA
    currentPosition: 'Next Station A', // Example current position
    isSandingLocation: false, // Example sanding location check
    headSandboxPairAvg: '55%',
    tailSandboxPairAvg: '35%',
    colorArray:['#8884d8', '#82ca9d', '#ffc658', '#ff7300']
  },
  {
    status: '',
    sandboxes: [],
    fleet: 444,
    trainId: 444013,
    sandboxIds: process.env.REACT_SANDBOX_EXTERNAL_KEY_1!.split(','),
    travellingFrom: 'BEDHAMPTON',
    travellingTo: 'HAVANT',
    estimatedArrival: '2024-07-17T12:00:00Z', // Example ETA
    currentPosition: 'Next Station A', // Example current position
    isSandingLocation: false, // Example sanding location check
    headSandboxPairAvg: '55%',
    tailSandboxPairAvg: '35%',
    colorArray:['#8884d8', '#82ca9d', '#ffc658', '#ff7300']
  },
];

const SandboxTrainListContainer: React.FC = ({
  appProps,
  organizationProps,
}: CemitAppOrganizationDependencyProps) => {
  const [selectedTrainSandbox, setSelectedTrainSandbox] =
    useState<TrainAndSandBox | null>(null);
  const trainDataList = generateTrainData();

  return (
    <Box
      sx={{my: 2, width: '100%', overflowY: 'auto', maxHeight: '100vh', padding: '1rem'}}
    >
      <Grid container alignItems="center" sx={{width: '100%'}} spacing={2}>
        <SandboxHeaderBar
          {...{
            appProps,
            organizationProps,
          }}
        />

        {selectedTrainSandbox && (
          <>
            <Grid item xs={12} sm={6} md={5}>
              <Typography variant="h6" sx={{color: 'whitesmoke'}}>
                {selectedTrainSandbox.trainData.trainId}
              </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              {/* <StatusRow refetch={refetch} /> */}
            </Grid>
          </>
        )}
      </Grid>

      {selectedTrainSandbox && (
        <>
          <Grid container spacing={2} sx={{mb: '1rem'}}>
            {selectedTrainSandbox.sandboxes &&
              selectedTrainSandbox.sandboxes.length > 0 && (
                <>
                  {selectedTrainSandbox.sandboxes.map((datastream, index) => (
                    <Grid item xs={12} sm={6} md={1} lg={1.5} key={index}>
                      <SandBoxCard
                        name="+100 Wheel Pos 6"
                        datastreamId="_PTjHksttJoo3sIrq-hZOQ"
                        currentLevel={20}
                        Datastream={datastream}
                        color ={datastream.color}
                        colorArray = {selectedTrainSandbox.trainData.colorArray[index]}
                      />
                    </Grid>
                  ))}
                  <Grid item xs={12} sm={6} md={8} lg={6}>
                    <SandboxTrainDetail
                      sandBoxHistoricData={selectedTrainSandbox.historicalData!}
                      colorArray={selectedTrainSandbox.trainData.colorArray}
                    />
                  </Grid>
                </>
              )}
          </Grid>
        </>
      )}

      <Grid container spacing={2}>
        <Grid item xs={4} sm={6}>
          <SearchBar />
          <Chip label="None" onDelete={() => {}} />
          <Chip label="None" onDelete={() => {}} />
          <Chip label="Parameter 1" onDelete={() => {}} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TrainFilters />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TableContainer component={Paper} sx={{padding: '2rem', overflowX: 'auto'}}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel>
                      <span style={{fontWeight: 'bold'}}>Status</span>
                    </TableSortLabel>
                  </TableCell>
                  
                  <TableCell>
                    <span style={{fontWeight: 'bold'}}>Sandboxes</span>
                  </TableCell>

                  <TableCell>
                    <span style={{fontWeight: 'bold'}}>Last Update</span>
                  </TableCell>

                  <TableCell>
                    <span style={{fontWeight: 'bold'}}>Fleet</span>
                  </TableCell>
                  <TableCell>
                    <span style={{fontWeight: 'bold'}}>Train Id</span>
                  </TableCell>
                  <TableCell>
                    <span style={{fontWeight: 'bold'}}>Travelling From</span>
                  </TableCell>
                  <TableCell>
                    <span style={{fontWeight: 'bold'}}>Travelling To</span>
                  </TableCell>
                  <TableCell>
                    <span style={{fontWeight: 'bold'}}>Head Sandbox Pair Avg</span>
                  </TableCell>
                  <TableCell>
                    <span style={{fontWeight: 'bold'}}>Tail Sandbox Pair Avg</span>
                  </TableCell>
                  <TableCell>
                    <span style={{fontWeight: 'bold'}}>Current Position</span>
                  </TableCell>
                  <TableCell>
                    <span style={{fontWeight: 'bold'}}>Action</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              {trainDataList.map((trainData, index) => (
                <SandboxTrainListTable
              
                  selectedTrain={!!selectedTrainSandbox}
                  key={`index_${trainData.trainId}`}
                  onSelectTrain={setSelectedTrainSandbox!}
                  trainsData={trainData}
                />
              ))}
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SandboxTrainListContainer;
