import {
  chain,
  filter,
  forEach,
  identity,
  includes,
  join,
  last,
  lensProp,
  map,
  prop,
  set,
  sortBy,
  split, unnest
} from 'ramda';
import {Map} from 'mapbox-gl';
import {getValidPoints} from 'appUtils/alertUtils/alertDataUtils.ts';
import {TrainRouteOrGroup} from 'types/trainRouteGroups/trainRouteOrGroup';
import {StateSetter} from 'types/hookHelpers/stateSetter';
import {DataThreshold} from 'types/dataVisualizations/dataThreshold';
import {Feature, FeatureCollection, Point} from 'geojson';

import {
  unlessLoadingProps,
  unlessLoadingValue,
} from 'utils/componentLogic/loadingUtils.ts';
import {
  CreateOnHoverProps,
  setMapboxOnHover,
} from '../cemitAppAsync/cemitAppHooks/mapHooks/trainMapHooks.ts';
import {
  removeMapboxLayersAndSources,
  setMapboxSourceAndLayersSets,
} from 'utils/map/mapboxSourceUtils.ts';
import {
  ALERT_TRAIN_GROUP_LAYER_PREFIX,
  ALERT_TRAIN_GROUP_SOURCE_PREFIX,
} from 'config/appConfigs/trainConfigs/trainConfig.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {ChartPayloadItemMinimized} from 'types/dataVisualizations/chartPayloadItem';
import {singlePointFeatureFromPayload} from 'utils/dataFeatures/dataFeaturePayloadUtils.ts';
import {
  MapboxIconConfig,
  MapboxLayer,
  MapSourceVisual,
  MapSourceVisualForTrainGroup,
} from 'types/mapbox/mapSourceVisual';
import {useNotLoadingMemo, useNotLoadingSetterEffect} from 'utils/hooks/useMemoHooks.ts';
import {typeObject} from 'appUtils/typeUtils/typeObject.ts';
import {MapHookDependencyProps} from 'types/propTypes/mapPropTypes/mapHookDependencyProps.ts';

import {TrainGroup} from 'types/trainGroups/trainGroup';
import {AlertScopeProps} from 'types/alerts/alertScopeProps';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {classifyObject} from 'appUtils/typeUtils/classifyObject.ts';
import {TrainGroupAlertGeojson} from 'types/trainGroups/trainGroupAlertGeojson.ts';
import {updateClsOrTypeDateAndVersion} from 'utils/versionUtils.ts';
import {clsOrType, ts} from 'appUtils/typeUtils/clsOrType.ts';
import {compact} from 'utils/functional/functionalUtils.ts';
import {
  memoizedTrainGroupIconImagesSourceAndLayers,
  MemoizedTrainGroupIconImagesSourceAndLayersProps,
} from 'utils/map/layers/dynamicIconLayers.ts';
import {alertAlertLevelPriority} from 'appUtils/alertUtils/alertUtils.ts';
import {alertIncomingOrExistingStatusIsIncomplete} from 'appUtils/alertUtils/alertScopePropsUtils.ts';
import {AlertTypeConfig} from 'types/alerts/alertTypeConfig';
import {useMemoCurrentAlertTypeConfigVisibleAttributeAlertLevelEnum} from 'async/trainAppAsync/trainAppHooks/alertConfigHooks/alertTypeConfigVisibleAttributeAlertLevelEnumHooks.ts';
import {AlertTypeConfigVisibleAttributeAlertLevelEnum} from 'types/alerts/attributeAlertLevelEnums';
import {alertTypeConfigTrainGroupIconConfigs} from 'types/alerts/alertTypeTrainGroupIconConfigs.ts';

export type UseUpdate3dForAlertTrainMapHookProps = {
  trainMap: Map;
  trainRouteOrGroup: Perhaps<TrainRouteOrGroup>;
  set3dLayersUpdating: StateSetter<boolean>;
  featurePropPath: string;
  dataThresholds: DataThreshold[];
  dataColumns3DLayerAreVisible: boolean;
  extrude: boolean;
  // Set these to help with zooming to them later
  setAlertSourceVisuals: StateSetter<MapSourceVisual[]>;
};

/**
 * Creates the Alert Mapbox layers for each active TrainGroup
 * TODO we don't currently store trainGroup.alertGeojson with crud because it's only used by the map layer
 * @param loading If true, do nothing
 * @param appProps
 * @param organizationProps
 * @param trainProps
 * @param mapProps
 * @param hookProps
 */
export const useNotLoadingEffectUpdateAlertLayerForAlertTrainMap = (
  loading: boolean,
  {
    appProps,
    organizationProps,
    trainProps,
    mapProps,
    hookProps,
  }: MapHookDependencyProps<UseUpdate3dForAlertTrainMapHookProps>,
) => {
  const {
    trainMap,
    set3dLayersUpdating,
    featurePropPath,
    dataThresholds,
    dataColumns3DLayerAreVisible,
    extrude,
  } = unlessLoadingProps(
    loading,
    () => hookProps as UseUpdate3dForAlertTrainMapHookProps,
  );
  const alertScopePropsSets = trainProps.alertProps.alertScopePropSets;
  const alertTypeConfig = trainProps.alertConfigProps.alertTypeConfig;

  const trainGroups = useNotLoadingMemo(
    loading,
    (alertScopePropsSets: AlertScopeProps[]): Perhaps<TrainGroup>[] => {
      // TODO we don't currently transpose the Alert features perpendicular to the track, but
      // we probably should do so that different TrainGroups' data don't overlap
      return map((alertScopeProps: AlertScopeProps) => {
        const scopedTrainGroup = alertScopeProps.scopedTrainGroup;
        // If the incoming or existing loading status is not complete, data and thus geojson are not available
        const loading = alertIncomingOrExistingStatusIsIncomplete(alertScopeProps);

        if (loading) {
          return typeObject<TrainGroup>(CemitTypename.trainGroup, {
            ...scopedTrainGroup,
            sensorDataGeojson: undefined,
          });
        } else {
          const heatMapData =
            alertScopeProps!.scopedTrainGroup!.alertScopeProps!.alertTrainGroupProps!
              .heatMapData!.data!;
          const featureCollection: Perhaps<FeatureCollection<Point>> = unlessLoadingValue(
            loading,
            () => {
              const features: Feature<Point>[] = getValidPoints(heatMapData);
              return {
                type: 'FeatureCollection',
                features: features,
              } as FeatureCollection<Point>;
            },
          );
          // TrainGroups can have geojson data independent of Alert
          return unlessLoadingValue(loading, () => {
            const trainGroupAlertGeojson = updateClsOrTypeDateAndVersion(
              clsOrType<TrainGroupAlertGeojson>(CemitTypename.alertTrainGroupGeojson, {
                activeDateInterval: scopedTrainGroup.activeDateInterval!,
                featureCollections: [featureCollection!],
                transformedFeatureCollection: featureCollection,
              }),
            );
            return classifyObject<typeof scopedTrainGroup>(scopedTrainGroup.__typename, {
              ...scopedTrainGroup,
              alertGeojson: trainGroupAlertGeojson,
            });
          });
        }
      }, alertScopePropsSets);
    },
    [alertScopePropsSets] as const,
  );

  const alertTypeConfigVisibleAttributeAlertLevelEnum: AlertTypeConfigVisibleAttributeAlertLevelEnum =
    useMemoCurrentAlertTypeConfigVisibleAttributeAlertLevelEnum(trainProps);
  // dataThresholds are marked as not visible unless sourceKey is in appProps.visibleAttributeAlertLevels
  const visibilityDataThresholds = useNotLoadingMemo(
    loading,
    (
      dataThresholds: DataThreshold[],
      alertTypeConfigVisibleAttributeAlertLevelEnum: AlertTypeConfigVisibleAttributeAlertLevelEnum,
    ) => {
      return map((dataThreshold: DataThreshold) => {
        return set(
          lensProp('isVisible'),
          includes(
            dataThreshold.sourceKey,
            alertTypeConfigVisibleAttributeAlertLevelEnum.visibleAttributeAlertLevelEnums,
          ),
          dataThreshold,
        );
      }, dataThresholds);
    },
    [dataThresholds, alertTypeConfigVisibleAttributeAlertLevelEnum],
  );
  return useNotLoadingSetterEffect(
    {
      loading: loading || !trainGroups,
      inProcessSetter: set3dLayersUpdating,
    },
    alertHeatMapLayers,
    ts<AlertHeatMapLayersProps>({
      alertTypeConfig,
      trainGroups,
      featurePropPath,
      dataThresholds: visibilityDataThresholds,
      extrude,
    }),
    // Update the trainMap to the sources and layers that are defined.
    // Those not defined indicate that the corresponding TrainGroup's Alert is in a loading state
    (mapSourceVisualSetPairs: MapSourceVisualForTrainGroup[][]) => {
      const heatMapSourceVisuals = filter(
        Boolean,
        unnest(mapSourceVisualSetPairs),
      );
      // We need to store these for zooming
      hookProps.setAlertSourceVisuals(heatMapSourceVisuals);
      // Set the heatmap sources and layers on TrainMap
      setMapboxSourceAndLayersSets(
        trainMap,
        mapProps.setChangeStatuses,
        heatMapSourceVisuals,
        mapProps.setMapboxImages,
        true,
      );
      // Move these layers to the top so they are above tracks
      // Sort by Alert type so that the more important errors are on top
      // Prioritize AttributeAlertLevel so we can sort the icons
      const allLayers = chain((heatMapSourceVisual: MapSourceVisualForTrainGroup) => {
        return heatMapSourceVisual.layers;
      }, heatMapSourceVisuals);
      const sortedLayersAscending = sortBy((layer: MapboxLayer) => {
        const alertType = last(split('-', layer.id));
        return alertAlertLevelPriority(
          trainProps.alertConfigProps.alertTypeConfig.attibuteAlertLevelEnum,
        )[alertType];
      }, allLayers);
      forEach((layer: MapboxLayer) => {
        trainMap.moveLayer(layer.id);
      }, sortedLayersAscending);

      // Create a hover popup that shows info about the TrainGroup and AlertType
      setMapboxOnHover({
        appProps,
        organizationProps,
        trainProps,
        mapProps,
        dataProps: {
          mapSourceVisuals: heatMapSourceVisuals,
          createOnHover: alertMapboxSourcesAndLayersSetsOnClick,
          // Create on click, not hover
          onClickOnly: true,
        },
      });

      // Remove any RIDE_COMFORT_TRAIN_GROUP_LAYER_PREFIX layers from previous iterations that are no longer present
      removeMapboxLayersAndSources({
        layerPrefix: ALERT_TRAIN_GROUP_LAYER_PREFIX,
        sourcePrefix: ALERT_TRAIN_GROUP_SOURCE_PREFIX,
        preserveSourceVisuals: heatMapSourceVisuals,
        mapboxMap: trainMap,
      });
    },
    [
      trainGroups,
      // Update if the user toggles columns
      dataColumns3DLayerAreVisible,
      // Update if the feature props the user is looking at change
      featurePropPath,
      trainProps?.alertProps.alertScopePropSets,
      appProps?.currentAppPage,
      visibilityDataThresholds,
    ],
  );
};

interface AlertHeatMapLayersProps {
  alertTypeConfig: AlertTypeConfig;
  trainGroups: TrainGroup[];
  featurePropPath: string;
  dataThresholds: DataThreshold[];
  extrude: boolean;
}

/**
 * Creates the Alert Icon layers for each TrainGroup and for each critical and warning AlertLevels.
 * Icons for lower alerts are not shown. The critical and warning alerts are put in separate layers so that
 * the former can always appear above the latter
 *
 * @param alertGeojsonTrainGroups The TrainGroup instances that are being displayed
 * @param featurePropPath The path in each feature.properties to use for the heatmap value.
 * @param dataThresholds Thresholds for coloring the 3d data based on value
 * @param extrude Whether to extrude the data or not to make a 3d visualization on the map
 */
const alertHeatMapLayers = ({
  alertTypeConfig,
  trainGroups,
  featurePropPath,
  dataThresholds,
  extrude,
}: AlertHeatMapLayersProps): MapSourceVisual[] => {
  // Creates a MapSourceVisual for each TrainGroup if its geojson is loaded
  const mapSourceVisuals: Perhaps<MapSourceVisual>[] = compact(
    map((trainGroup: TrainGroup) => {
      // If the incoming or existing loading status is not complete, data and thus geojson are not available
      const loading = !trainGroup.alertGeojson;
      if (loading) {
        return undefined;
      }
      const sensorPointsTransformed =
        trainGroup.alertGeojson.transformedFeatureCollection;

      if (!trainGroup!.activity!.isActiveColor) {
        throw new Error('trainGroup.activity.isActiveColor is not defined');
      }

      // Creates an MapboxIconConfig based on the trainGroup id and activity.isActiveColor
      const trainGroupIconConfigs: MapboxIconConfig[] =
        alertTypeConfigTrainGroupIconConfigs(alertTypeConfig, trainGroup);

      // Creates a MapSourceVisual for each TrainGroup. Each MapSourceVisual has two layers, one for
      // critical and one for warning alerts
      const columnSourceAndLayers: MapSourceVisual =
        memoizedTrainGroupIconImagesSourceAndLayers(
          ts<MemoizedTrainGroupIconImagesSourceAndLayersProps>({
            trainGroup: trainGroup,
            trainGroupIconConfigs,
            featurePropPath,
            geojson: sensorPointsTransformed,
            dataThresholds,
            extrude,
            sourceNamePrefix: ALERT_TRAIN_GROUP_SOURCE_PREFIX,
            layerNamePrefix: ALERT_TRAIN_GROUP_LAYER_PREFIX,
            createOneLayerPerThreshold: true,
          }),
        );

      return columnSourceAndLayers;
    }, trainGroups),
  );
  return mapSourceVisuals;
};

/**
 * Popup to describe ride comfort
 * @param appProps
 * @param organizationProps
 * @param mapProps
 * @param eventProps
 * @param payload
 */
export const alertMapboxSourcesAndLayersSetsOnClick = (
  {appProps, organizationProps, mapProps, eventProps}: CreateOnHoverProps,
  payload: ChartPayloadItemMinimized[],
): void => {
  eventProps.popup.remove();
  const {t} = appProps;

  const feature = singlePointFeatureFromPayload(payload);
  const properties = feature.properties;
  if (!feature || !properties) {
    return;
  }
  const description = join(
    '<br/>',
    compact([
      `Train: ${eventProps.trainGroup.localizedName(organizationProps.organization!.timezoneStr)}`,
      `${properties.timestamp}`,
      // Hunting only
      feature.properties.speed ? `Speed: ${feature.properties.speed} km/h` : undefined,
      // Hunting only
      feature.properties.energy ? `Energy: ${feature.properties.energy}` : undefined,
      !feature.properties.speed ? `Level: ${properties.value.toFixed(1)}` : undefined,
      `Lat-lon: ${feature.geometry.coordinates[1].toFixed(4)}, ${feature.geometry.coordinates[0].toFixed(4)}`,
    ]),
  );

  // Populate the popup and set its coordinates
  // based on the feature found.
  eventProps.popup
    .setLngLat(eventProps.e.lngLat)
    .setHTML(`<div style='font-size: 12px;'>${description}</div>`)
    .addTo(mapProps.trainMap);
};
