import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { TagData } from '../types';
import { throwUnlessDefined } from 'utils/functional/functionalUtils.ts';

// Debounce hook to prevent rapid successive API calls
function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    // Cleanup the timeout on value change or unmount
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
}

// Function to fetch tag data from the API with multiple IDs
const fetchDataStream = async (referenceIds: string[]): Promise<TagData[]> => {
  const REACT_APP_DETECTOR_API_URL = throwUnlessDefined(process.env.REACT_APP_DETECTOR_API_URL);

  // Construct the URL with multiple reference_ids
  const queryString = referenceIds
    .map((id) => `reference_ids=${encodeURIComponent(id)}`)
    .join('&');
  const detectorApiUrl = `${REACT_APP_DETECTOR_API_URL}?${queryString}&orderBy=-detection-time&page=1&pageSize=10`;

  const response = await fetch(detectorApiUrl, {
    method: 'GET',
    headers: { accept: 'application/json' },
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  const result = await response.json();

  // Ensure the result is an array
  if (Array.isArray(result)) {
    return result;
  } else if (result && Array.isArray(result.data)) {
    return result.data;
  } else {
    throw new Error('Unexpected API response structure');
  }
};

// Function to fetch trends data from the new API
const fetchTrendsData = async (referenceIds: string[]): Promise<Record<string, any[]>> => {
  const baseUrl = 'https://api.core-dev.cemit.digital/api/v1/detector/event_rssi_trends';

  const queryString = referenceIds
    .map((id) => `reference_ids=${encodeURIComponent(id)}`)
    .join('&');
  const trendsApiUrl = `${baseUrl}?${queryString}`;

  const response = await fetch(trendsApiUrl, {
    method: 'GET',
    headers: { accept: 'application/json' },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch trends data');
  }

  const result = await response.json();

  // Group trends data by detector_id
  if (result ) {
    return result
  } else {
    throw new Error('Unexpected API response structure');
  }
};

// Custom hook to fetch tag and trends data using React Query
const useFetchTags = (datastreamIds: string[] = []) => {
  const [errors, setErrors] = useState<string[]>([]);
  const debouncedDatastreamIds = useDebounce(datastreamIds, 200);

  // Fetch tag data
  const { data: tagsData, isLoading: isTagsLoading, error: tagsError } = useQuery({
    queryKey: ['fetchTags', debouncedDatastreamIds],
    queryFn: () => fetchDataStream(debouncedDatastreamIds),
    retry: 3,
    retryDelay: (attempt) => Math.min(1000 * 2 ** attempt, 30000),
    enabled: debouncedDatastreamIds.length > 0,
  });

  // Fetch trends data
  const { data: trendsData, isLoading: isTrendsLoading, error: trendsError } = useQuery({
    queryKey: ['fetchTrends', debouncedDatastreamIds],
    queryFn: () => fetchTrendsData(debouncedDatastreamIds),
    retry: 3,
    retryDelay: (attempt) => Math.min(1000 * 2 ** attempt, 30000),
    enabled: debouncedDatastreamIds.length > 0,
    staleTime: 5 * 60 * 1000, // Cache trends data for 5 minutes
  
  });

  // Handle errors
  useEffect(() => {
    if (tagsError) {
      setErrors((prevErrors) => [...prevErrors, (tagsError as Error).message]);
    }
    if (trendsError) {
      setErrors((prevErrors) => [...prevErrors, (trendsError as Error).message]);
    }
  }, [tagsError, trendsError]);

  // Combine tags and trends data
  const combinedData = Array.isArray(tagsData)
  ? tagsData.map((tag) => {
      // Find the corresponding trend for the tag
      const trend = Array.isArray(trendsData)
        ? trendsData.find((t) => t.detectorId === tag.referenceId)
        : null;

      return {
        ...tag,
        trends: trend 
      };
    })
  : [];
    
    useEffect(() => {
    
    }, [tagsData, trendsData, combinedData]);

  return {
    data: combinedData,
    isLoading: isTagsLoading || isTrendsLoading,
    errors,
  };
};

export default useFetchTags;
