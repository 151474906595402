import {TrainGroup} from 'types/trainGroups/trainGroup.ts';
import {
  resolveAlertScoreForTimePeriod,
  resolveCriticalAlertCountForTimePeriod,
  resolveOverviewAlertDataForTimePeriod
} from 'appUtils/alertUtils/alertUtils.ts';
import {
  AlertOverviewByTimePeriod,
  AlertOverviewByTimePeriodOrPrevious,
  AlertOverviewByTimePeriodPrevious
} from 'types/alerts/alertOverviewByTimePeriod.ts';
import {TFunction} from 'i18next';
import {createElement, ReactElement} from 'react';
import {GraphqlResponseDateIntervalOverviewAlertData} from 'types/alerts/alertMapData.ts';
import {Stack, Tooltip} from '@mui/material';
import {
  always,
  any,
  cond,
  either,
  equals,
  gt, identity,
  includes,
  is,
  isNil,
  join,
  lt, map,
  mapObjIndexed,
  memoizeWith, mergeDeepRight,
  T,
  values
} from 'ramda';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {
  TrainAppTrainDependencyProps
} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppTrainDependencyProps.ts';
import {reqStrPathThrowing} from '@rescapes/ramda';
import {AlertTypeKey} from 'types/alerts/alertTypeKey.ts';
import {AlertLevels} from 'types/alerts/alertLevels.ts';
import {compact, findOrThrow, roundWithoutNegativeZero, toArrayIfNot} from 'utils/functional/functionalUtils.ts';
import {AlertTypeConfig} from 'types/alerts/alertTypeConfig.ts';
import {AttributeAlertLevel} from 'types/alerts/attributeAlertLevelEnums.ts';
import {TableGroupOverviewTableColumn} from 'types/summaryPresentations/tableGroupOverviewTableColumn.ts';
import {TrendQuaternary, TrendQuaternaryAndDiff} from 'types/logic/trendQuaternary.ts';
import {SortDirection} from 'types/sorting/sortDirection.ts';
import {capitalizeFirstLetter} from 'apollo-client/util/capitalizeFirstLetter';
import {TrainGroupClassification} from 'types/trainGroups/trainGroupClassification.ts';
import {cemitColors} from 'theme/cemitColors.ts';
import TableGroupOverviewCellSpan
  from 'components/apps/trainAppComponents/trainAppBoardComponents/trainGroupOverviewComponents/TableGroupOverviewCellSpan.tsx';
import {ArrowDownward, ArrowUpward, HorizontalRule, TrendingFlat} from '@mui/icons-material';
import {translateUnlessNumber} from 'utils/translationUtils.ts';
import {rideComfortAlertConfig} from 'types/alerts/rideComfort/rideComfortAlertConfig.ts';

/**
 * Compares percent of normal readings in alertOverviewByTimePeriod to percent of normal readings in alertOverviewByTimePeriodPrevious.
 * Returns an up, down, or flat value of TrendQuaternary.  If either percent is NaN, returns unknown
 * @param alertOverviewByTimePeriod
 * @param alertOverviewByTimePeriodPrevious
 * @param trainGroup
 */
export const trendOfAlertsForTrainGroup = (
  alertOverviewByTimePeriod: AlertOverviewByTimePeriod,
  alertOverviewByTimePeriodPrevious: Perhaps<AlertOverviewByTimePeriodPrevious>,
  trainGroup: TrainGroup
): TrendQuaternaryAndDiff => {
  const todayPercent: number = resolveAlertScoreForTimePeriod(alertOverviewByTimePeriod, trainGroup);
  // The largest time interval doesn't have a previous time interval, the others do
  const previousPercent: Perhaps<number> = alertOverviewByTimePeriodPrevious ? resolveAlertScoreForTimePeriod(alertOverviewByTimePeriodPrevious, trainGroup) : undefined;
  // If the value couldn't be computed due to lack of data, return unknown
  if (any(either(isNaN, isNil), [todayPercent, previousPercent])) {
    return {trendQuaternary: TrendQuaternary.unknown, diff: NaN} as TrendQuaternaryAndDiff;
  }
  return getTrendQuaternaryAndDiff(todayPercent - previousPercent!);
};

/**
 * Returns an TrendQuaternary.up for positive trend, a TrendQuaternary.down arrow for negative trend, and a TrendQuaternary.flat for no change trends
 * @param diff
 */
export const getTrendQuaternaryAndDiff = (diff: number): TrendQuaternaryAndDiff => {
  const trendQuaternary: TrendQuaternary = cond([
    [lt(0), always(TrendQuaternary.up)],
    [equals(0), always(TrendQuaternary.flat)],
    [gt(0), always(TrendQuaternary.down)],
    [isNaN, always(TrendQuaternary.unknown)]
  ])(diff);
  return {
    trendQuaternary,
    // Don't allow -0
    diff: diff + 0
  } as TrendQuaternaryAndDiff;
};

/**
 * Resolves the count of critical alerts for the trainGroup in the given AlertOverviewByTimePeriod
 * @param currentTimePeriod
 * @param _previousTimePeriod Unused, but matches the resolve function interface
 * @param trainGroup
 */
export const resolveCriticalAlertCount = (currentTimePeriod: AlertOverviewByTimePeriodOrPrevious, trainGroup: TrainGroup): number => {
  // Translation not needed for calling memoizedCriticalAlertLevelKeys
  const t = identity
  const criticalAlertLevelKeys = memoizedCriticalAlertLevelKeys(rideComfortAlertConfig(t));
  return resolveCriticalAlertCountForTimePeriod(currentTimePeriod, trainGroup, criticalAlertLevelKeys);
};

/**
 * Resolves the count of critical alerts for the trainGroup in the given AlertOverviewByTimePeriod and adds the number of operations per day, returning a ReactElement
 * @param t
 * @param currentTimePeriod
 * @param trainGroup
 */
export const resolveCriticalAlertCountElement = (t: TFunction, currentTimePeriod: AlertOverviewByTimePeriod, trainGroup: TrainGroup): ReactElement => {
  const criticalAlertLevelKeys = memoizedCriticalAlertLevelKeys(rideComfortAlertConfig(t));
  return createElement(TableGroupOverviewCellSpan, {key: 'criticalAlerts'}, `${resolveCriticalAlertCountForTimePeriod(currentTimePeriod, trainGroup, criticalAlertLevelKeys)}`);
};


/**
 * Return the overview alert percent for the dataIntervalLabel.
 * If the percent is NaN, it means there were no alerts at any level, so 'noData' is returned
 * @param t
 * @param dataIntervalLabel
 * @param trainGroup
 */
export const resolveAlertScoreForTimePeriodOrNoData = (
  t: TFunction,
  dataIntervalLabel: AlertOverviewByTimePeriod,
  trainGroup: TrainGroup
): string => {
  const percent: number = resolveAlertScoreForTimePeriod(dataIntervalLabel, trainGroup);
  if (isNaN(percent)) {
    return t('noData');
  }
  return `${percent.toFixed(0)}%`;
};

/**
 * Return the diff in resolveAlertScoreForTimePeriod from current to the previous or NaN
 * if one is missing
 */
export const resolveAlertRateOfChangeScoreForTimePeriod = (
  dataIntervalLabel: AlertOverviewByTimePeriod,
  previousDataIntervalLabel: Perhaps<AlertOverviewByTimePeriod>,
  trainGroup: TrainGroup
): number => {
  const previousPercent: number = previousDataIntervalLabel ? resolveAlertScoreForTimePeriod(previousDataIntervalLabel, trainGroup) : NaN;
  const percent: number = resolveAlertScoreForTimePeriod(dataIntervalLabel, trainGroup);
  if (isNaN(percent)) {
    return percent;
  } else if (isNaN(previousPercent)) {
    return previousPercent;
  }
  return roundWithoutNegativeZero(percent - previousPercent);
};
/**
 * Return a label that is the percent page between the period previousDataIntervalLabel and dataIntervalLabel
 * The value will either be +percent% -percent& or 0%
 * @param t
 * @param dataIntervalLabel
 * @param previousDataIntervalLabel
 * @param trainGroup
 */
export const resolveAlertRateOfChangeScoreForTimePeriodOrNoData = (
  t: TFunction,
  dataIntervalLabel: AlertOverviewByTimePeriod,
  previousDataIntervalLabel: AlertOverviewByTimePeriod,
  trainGroup: TrainGroup
): number => {
  const previousPercent: number = resolveAlertScoreForTimePeriod(previousDataIntervalLabel, trainGroup);
  const percent: number = resolveAlertScoreForTimePeriod(dataIntervalLabel, trainGroup);
  if (isNaN(percent)) {
    // No current data, so previous doesn't matter
    return percent;
  }
  if (isNaN(previousPercent)) {
    // The current interval has data but the previous doesn't
    return previousPercent;
  }
  const diff = resolveAlertRateOfChangeScoreForTimePeriod(dataIntervalLabel, previousDataIntervalLabel, trainGroup);
  // Prevent -0
  return diff + 0;
};

/**
 * Renders the given rage of change percent with the given color
 * @param t
 * @param currentTimePeriod Current period AlertOverviewByTimePeriod
 * @param previousTimePeriod The previous period of the same length if available
 * @param noData True if something was NaN or unavailable
 * @param rateOfChange The rate of change to display
 */
export const rateOfChangePercentElement = (
  t: TFunction,
  currentTimePeriod: AlertOverviewByTimePeriod,
  previousTimePeriod: Perhaps<AlertOverviewByTimePeriodPrevious>,
  noData: boolean,
  rateOfChange: number
) => {

  const sign = cond([
    // Already signed negative
    [(diff: number) => diff < 0, always('')],
    // Sign with +
    [(diff: number) => diff > 0, always('+')],
    // No change
    [T, always('')]
  ])(rateOfChange);
  const rateOfChangePercent = `${sign}${rateOfChange.toFixed(0)}%`;
  const color: Perhaps<string> = previousTimePeriod ? cond([
    [includes('+'), always(cemitColors.trackGreenLighter)],
    [includes('-'), always(cemitColors.reallyRed)],
    [T, always('white')]
  ])(rateOfChangePercent) : undefined;

  return createElement(Tooltip, {
      arrow: true,
      title: previousTimePeriod ? `${t('percentNormalReadings')} (${t('percentChangeFrom')} ${t('from')} ${t(previousTimePeriod)} ${t('to')} ${t(currentTimePeriod)})` : t('percentNormalReadings')
    },
    noData ?
      createElement(Stack, {key: 'overviewPercent'}, t('noData')) :
      (previousTimePeriod ?
        createElement(Stack, {key: 'ops', spacing: 0.5, direction: 'row', sx: {justifyContent: 'flex-end'}}, [
          createElement(TableGroupOverviewCellSpan, {
            key: 'rateOfChangePercent',
            style: {color, fontWeight: 'bold'}
          }, rateOfChangePercent)
        ]) : undefined)
  );
};

/**
 * Computes a current time period performance value as well as the previous time period
 * (e.g. today and yesterday or last 30 months, and penultimate 30 months)
 * Returns `[current performance percnnt] (+/- change since previous period percent)`
 * @param t
 * @param currentTimePeriod
 * @param previousTimePeriod
 * @param trainGroup
 */
export const resolvePercentNormalReadingsRateOfChangeElement = (t: TFunction, currentTimePeriod: AlertOverviewByTimePeriod, previousTimePeriod: Perhaps<AlertOverviewByTimePeriod>, trainGroup: TrainGroup): ReactElement => {
  const overviewPercent: string = resolveAlertScoreForTimePeriodOrNoData(t, currentTimePeriod, trainGroup);
  const diff: number = previousTimePeriod ? resolveAlertRateOfChangeScoreForTimePeriodOrNoData(t, currentTimePeriod, previousTimePeriod, trainGroup) : NaN;
  return rateOfChangePercentElement(
    t,
    currentTimePeriod,
    previousTimePeriod,
    isNaN(diff) || overviewPercent == t('noData'),
    diff
  );
};

/**
 * Calls the given column's resolveValue function if it has one or resolves the column's propPath
 * This gives the value for the cell of the column of the given trainGroup
 * @param trainAppTrainDependencyProps
 * @param trainGroup
 * @param column
 */
export const resolveTrainGroupOverviewTableColumnValue = (trainAppTrainDependencyProps: TrainAppTrainDependencyProps, trainGroup: TrainGroup, column: TableGroupOverviewTableColumn) => {
  return column.resolveValue ?
    column.resolveValue(trainAppTrainDependencyProps, trainGroup) :
    reqStrPathThrowing(column.propPath, trainGroup);
};

/**
 * Calls the given column's resolveValueForTrainGroupClassification function if it has one or resolves the column's propPath
 * This gives the value for the cell of the column of the given trainGroup
 * @param trainAppTrainDependencyProps
 * @param trainGroupClassification
 * @param column
 */
export const resolveTrainGroupClassificationOverviewTableColumnValue = (trainAppTrainDependencyProps: TrainAppTrainDependencyProps, trainGroupClassification: TrainGroupClassification, column: TableGroupOverviewTableColumn) => {
  return column.resolveValueForTrainGroupClassification(trainAppTrainDependencyProps, trainGroupClassification);
};

/**
 * Calls the given column's resolveValue function if it has one or resolves the column's propPath.
 * If column.resolveValueForSort is defined, resolves with this function got get a non-react Element value that
 * can be sorted on.
 * The function also converts NaN to -1 so that NaN isn't considered a high value
 * @param trainAppTrainDependencyProps
 * @param trainGroup
 * @param column
 */
export const resolveTrainGroupOverviewTableColumnValueForSort = (trainAppTrainDependencyProps: TrainAppTrainDependencyProps, trainGroup: TrainGroup, column: TableGroupOverviewTableColumn) => {
  const result = column.resolveValue ?
    // resolveValueForSort is needed for columns that normally return react components, which can't be sorted of course
    (column.resolveValueForSort || column.resolveValue)(trainAppTrainDependencyProps, trainGroup) :
    reqStrPathThrowing(column.propPath, trainGroup);
  return is(Number, result) && isNaN(result) ? -1 : result;
};
/**
 * Computes a current time period performance value and returns it in a component
 * @param t
 * @param currentTimePeriod
 * @param trainGroup
 */
export const resolveNormalAlertPercentValueElement = (t: TFunction, currentTimePeriod: AlertOverviewByTimePeriod, trainGroup: TrainGroup): ReactElement => {
  const overviewPercent = resolveAlertScoreForTimePeriodOrNoData(t, currentTimePeriod, trainGroup);
  return createElement(Tooltip, {
      arrow: true,
      title: t('percentNormalReadings')
    },
    createElement(Stack, {
        spacing: 0.5,
        direction: 'column',
        sx: {justifyContent: 'flex-end', minWidth: '40px'}
      },
      createElement(TableGroupOverviewCellSpan, {key: 'overviewPercent'}, overviewPercent)
    )
  );

};

/**
 * Computes the operations per day and returns it in a component
 * @param t
 * @param currentTimePeriod
 * @param previousTimePeriod
 * @param trainGroup
 */
export const resolveHoursInOperationElement = (t: TFunction, currentTimePeriod: AlertOverviewByTimePeriod, trainGroup: TrainGroup): ReactElement => {
  const overviewPercent = resolveAlertScoreForTimePeriodOrNoData(t, currentTimePeriod, trainGroup);
  const currentOverviewAlertData: GraphqlResponseDateIntervalOverviewAlertData = resolveOverviewAlertDataForTimePeriod(currentTimePeriod, trainGroup);
  return createElement(Tooltip, {
      arrow: true,
      title: t('hoursInOperationDescription')
    },
    createElement(Stack, {
        spacing: 0.5,
        direction: 'column',
        sx: {justifyContent: 'flex-end', minWidth: '40px'}
      },
      createElement(TableGroupOverviewCellSpan, {key: 'hoursInOperation'},
        overviewPercent != t('noData') ? `${formatOperationHours(currentOverviewAlertData.operationHours)} ${t('hours')}` : t('noData')
      )
    )
  );
};
const formatOperationHours = (operationHours: number) => {
  return operationHours == 0 ? `<1` : operationHours;
};

/**
 * The critical alert level keys for the AlertTypeConfig, e.g. 'L3'
 */
export const memoizedCriticalAlertLevelKeys = memoizeWith(
  (alertTypeConfig: AlertTypeConfig) => alertTypeConfig.alertTypeKey,
  (alertTypeConfig: AlertTypeConfig): (keyof AlertLevels)[] => {
    // Get what constitutes a critical alert level for the alertTypeConfig
    const criticalAlertLevels: AttributeAlertLevel[] = alertTypeConfig.criticalLevels;
    // Do a reverse lookup of alertLevelToAttribute to go from 'critical' to 'L2' or similar
    return compact(values(mapObjIndexed(
      (attribute: string, level: string) => {
        return includes(attribute, criticalAlertLevels) ? level : undefined;
      },
      alertTypeConfig.alertLevelToAttribute
    )));
  });

/**
 * The warning alert level keys for the AlertConfigType, e.g. 'L2'
 */
export const memoizedWarningAlertLevelKeys = memoizeWith(
  (alertTypeConfig: AlertTypeConfig) => alertTypeConfig.alertTypeKey,
  (alertTypeConfig: AlertTypeConfig): (keyof AlertLevels)[] => {
    // Get what constitutes a warning alert level for the alertTypeConfig
    const criticalAlertLevels: AttributeAlertLevel[] = alertTypeConfig.warningLevels;
    // Do a reverse lookup of alertLevelToAttribute to go from 'critical' to 'L2' or similar
    return compact(values(mapObjIndexed(
      (attribute: string, level: string) => {
        return includes(attribute, criticalAlertLevels) ? level : undefined;
      },
      alertTypeConfig.alertLevelToAttribute
    )));
  });

/**
 * Describes the sort and direction, depending on whether or not the sort is active. Returns undefined is
 * sort is disabled for the column
 * @param t
 * @param disableSort
 * @param isActiveSort
 * @param sortDirection
 */
export const sortActiveAndDirection = (t: TFunction, disableSort: Perhaps<boolean>, isActiveSort: boolean, sortDirection: SortDirection): Perhaps<string> => {
  const sortDirectionLabel = capitalizeFirstLetter(sortDirection);
  if (disableSort) {
    return undefined;
  } else if (isActiveSort) {
    return t(`activeSort${sortDirectionLabel}`);
  } else {
    // Clicking an inactive sort always makes it increasing
    return t('clickToActivateSortAsc');
  }
};

export interface TrainOverviewTrendType {
  icon: (props: any) => ReactElement,
  tooltipLabels: (number|string)[] | string
}

// The diff of the current time period minus the previous,
// and the trend icon component and the tooltip label for the icon
export interface TrainOverviewTrendTypeWithDiffAndIcon extends TrainOverviewTrendType {
  diff: number;
}

/**
 * Resolves the Trend icon for the given trainGroup in the given alertOverviewByTimePeriods
 * @param alertOverviewByTimePeriod
 * @param alertOverviewByTimePeriodPrevious If undefined, the trend is N/A since two periods
 * are needed to calculate a trend
 * @param trainGroup
 */
export const resolveTrainGroupOverviewTrendType = (
  alertOverviewByTimePeriod: AlertOverviewByTimePeriod,
  alertOverviewByTimePeriodPrevious: Perhaps<AlertOverviewByTimePeriodPrevious>,
  trainGroup: TrainGroup
): TrainOverviewTrendTypeWithDiffAndIcon => {
  // Get the trend direction and diff of the percent of normal alert readings in the current time period
  // minus the previous time period
  const trendQuaternaryAndDiff: TrendQuaternaryAndDiff = alertOverviewByTimePeriodPrevious ?
    trendOfAlertsForTrainGroup(alertOverviewByTimePeriod, alertOverviewByTimePeriodPrevious, trainGroup) :
    {trendQuaternary: TrendQuaternary.unknown, diff: NaN} as TrendQuaternaryAndDiff;
  return getTrainOverviewTrendTypeWithDiffAndIcon(trendQuaternaryAndDiff);
};

/**
 * Gets the icon component for the TrendQuaternaryAndDiff
 * The icon can be an up for increasing tren, down for desceasing trend, right for unchanged trend, or dash if any
 * data is missing
 * @param trendQuaternaryAndDiff
 */
export const getTrainOverviewTrendTypeWithDiffAndIcon = (trendQuaternaryAndDiff: TrendQuaternaryAndDiff): TrainOverviewTrendTypeWithDiffAndIcon => {
  const propFunc = (props: any, color: string) => {
    return mergeDeepRight(
      props,
      {sx: {color}}
    );
  };
  return {
    diff: trendQuaternaryAndDiff.diff,
    ...cond<[TrendQuaternary], TrainOverviewTrendType>([
      [
        equals(TrendQuaternary.up),
        always({
            icon: (props: any) => createElement(
              ArrowUpward,
              propFunc(props, cemitColors.trackGreenLighter)
            ),
            tooltipLabels: [roundWithoutNegativeZero(trendQuaternaryAndDiff.diff), 'performanceImproving']
          }
        )
      ],
      [
        equals(TrendQuaternary.down),

        always({
          icon: (props: any) => createElement(
            ArrowDownward,
            propFunc(props, cemitColors.reallyRed)
          ),
          tooltipLabels: [Math.abs(roundWithoutNegativeZero(trendQuaternaryAndDiff.diff)), 'performanceWorsening']
        })
      ],
      [
        equals(TrendQuaternary.flat),
        always({
          icon: (props: any) => createElement(
            TrendingFlat,
            propFunc(props, cemitColors.yellow)
          ),
          tooltipLabels: 'performanceFlat'
        })
      ],
      [
        equals(TrendQuaternary.unknown),
        always({
          icon: (props: any) => createElement(
            HorizontalRule,
            propFunc(props, cemitColors.grey)
          ),
          tooltipLabels: 'performanceUnknown'
        })
      ],
      [T, (trendQuaternary) => {
        throw new Error(`Unexpected PerformanceQuaternary value: ${trendQuaternary}`);
      }]
    ])(trendQuaternaryAndDiff.trendQuaternary)
  } as TrainOverviewTrendTypeWithDiffAndIcon;
};

/**
 * Creates and element containing a trend icon wrapped in a tooltip.
 * @param t
 * @param alertOverviewByTimePeriod
 * @param alertOverviewByTimePeriodPrevious
 * @param trainGroup
 */
export const resolveTrainGroupOverviewTrendIconElement = (
  t: TFunction,
  alertOverviewByTimePeriod: AlertOverviewByTimePeriod,
  alertOverviewByTimePeriodPrevious: Perhaps<AlertOverviewByTimePeriodPrevious>,
  trainGroup: TrainGroup
): ReactElement => {
  const trainOverviewTrendType: TrainOverviewTrendTypeWithDiffAndIcon = resolveTrainGroupOverviewTrendType(
    alertOverviewByTimePeriod,
    alertOverviewByTimePeriodPrevious,
    trainGroup
  );

  return createElement(
    Tooltip, {
      arrow: true,
      title: join(' ', map(
        tooltipLabel => {
          return translateUnlessNumber(t, tooltipLabel)
        },
        toArrayIfNot(trainOverviewTrendType.tooltipLabels))
      )
    },
    trainOverviewTrendType.icon({})
  );
};

