import {
  compose,
  divide,
  lt,
  map,
  mapObjIndexed,
  multiply,
  omit,
  reduce,
  values,
  when
} from 'ramda';
import {GraphqlResponseAlertData, AlertLevelDatum} from 'types/alerts/alertMapData';
import {AlertBaseGauge} from 'types/alerts/alertGauge';
import {clsOrType} from 'appUtils/typeUtils/clsOrType.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {AttributeAlertLevelEnum} from 'types/alerts/attributeAlertLevelEnum.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {AlertPeriodMapData} from 'types/alerts/alertPeriodMapData';
import {totalMapDataValues} from 'appUtils/alertUtils/setMapDataUtils.ts';
import {getTotalCount} from 'appUtils/alertUtils/graphqlResultUtils.ts';
import {AlertGaugeByTimePeriod} from 'types/alerts/alertGaugeByTimePeriod.ts';

/**
 * Convert the alertGraphqlResponseAlertData to AlertGaugeByTimePeriod based on alertTypeConfig
 * @param alertGraphqlResponseAlertData
 * @param maybeParentAlertGraphqlResponseAlertData The parent AlertTypeConfig response data
 * if needed to calculate percentages of alert types
 */
export const getAlertGaugeByTimePeriod = (
  alertGraphqlResponseAlertData: Perhaps<GraphqlResponseAlertData>,
  maybeParentAlertGraphqlResponseAlertData: Perhaps<GraphqlResponseAlertData>
): AlertGaugeByTimePeriod => {

  const alertTypeConfig = alertGraphqlResponseAlertData?.alertTypeConfig;
  // Total the returned data from the query
  const alertPeriodMapData: AlertPeriodMapData = totalMapDataValues(
    alertGraphqlResponseAlertData!.alertTypeConfig!,
    alertGraphqlResponseAlertData,
    getTotalCount
  );
  // If a parent AlertTypeConfig is defined, use its totals for the denominator of the percents.
  // This is due to an error in the backend of how RideComfortRoughRideDueToTrack and  RideComfortRoughRideDueToTrain
  // are calculated, so they need to use the paren RideComfort alert type for the denominator
  const parentOrNormalAlertPeriodMapData: AlertPeriodMapData = maybeParentAlertGraphqlResponseAlertData ? totalMapDataValues(
    alertGraphqlResponseAlertData!.alertTypeConfig!,
    maybeParentAlertGraphqlResponseAlertData,
    getTotalCount
  ) : alertPeriodMapData;

  // Calculate denominators based on the sum of all alert types
  const parentOrNormalAttributeAlertLevelEnums = omit(
    [AttributeAlertLevelEnum.none as string],
    // If the alertTypeConfig has parentAlertTypeConfig, uses its values for the totals
    // from which to derive the percents
    (
      alertGraphqlResponseAlertData!.alertTypeConfig!.parentAlertTypeConfig ||
      alertGraphqlResponseAlertData!.alertTypeConfig
    )!.attributeAlertLevelEnum
  );
  return mapObjIndexed(
    (alertLevelDatum: AlertLevelDatum, periodEnumAsString: string): AlertBaseGauge => {
      // The denominator is the sum of each alertLevelDatum value
      const parentOrNormalAlertLevelDatum: AlertLevelDatum = parentOrNormalAlertPeriodMapData[periodEnumAsString as keyof AlertPeriodMapData] as AlertLevelDatum;
      const denominator: number = reduce(
        (sum: number, key: string) => {
          return sum + parentOrNormalAlertLevelDatum[key as keyof AlertLevelDatum];
        },
        0,
        values(parentOrNormalAttributeAlertLevelEnums)
      );
      return clsOrType<AlertBaseGauge>(CemitTypename.alertGauge, {
        alertTypeConfig,
        periodEnum: periodEnumAsString,
        // calculate keys normal, warning, and critical
        ...map(
          (count: number) => {
            return {
              // Divide by the dividend and multiply by 100 when dividend is greater than 0
              value: when(
                lt(0),
                compose(
                  (quotient: number) => multiply(100, quotient),
                  (denominator: number) => divide(count, denominator)
                )
              )(denominator),
              count
            };
          },
          omit(['__typename'], alertLevelDatum)
        )
      });
    },
    alertPeriodMapData as unknown as Record<string, AlertLevelDatum>
  );
};
