import { useState, useCallback } from 'react';
import { read, utils, WorkBook } from 'xlsx';

interface ExcelData {
  detektor: string;          // detektor
  X: number;                 // X
  Y: number;                 // Y
  riktning: number;          // riktning
  tagID: string;             // tag-ID
  tagtyp: number;            // tagtyp
  sequenceNumber: number;    // sekv.nr
  radioNumber: number;       // radionr
  functionID: number;        // funk-ID
  object: string;            // objekt
  objectName: string;        // objektnamn
  equipmentRoom: string;     // apparatrum
  equipmentRoomName: string; // apparatrumsnamn
  source: number;            // källa
}

const useExcelToGeoJson = (fileUrl: string) => {
  const [pointsGeoJsonData, setPointsGeoJsonData] = useState<any>(null);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);

  const convertExcelToGeoJson = useCallback(async () => {
    try {
      const response = await fetch(fileUrl);
      if (!response.ok) {
        throw new Error(`Failed to fetch Excel file: ${response.statusText}`);
      }
      const arrayBuffer = await response.arrayBuffer();
      const workbook: WorkBook = read(arrayBuffer, { type: 'array' });
      parseExcelData(workbook);
    } catch (error) {
      console.error('Error fetching or parsing Excel file:', error);
    }
  }, [fileUrl]);

  const parseExcelData = useCallback((workbook: WorkBook) => {
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];
    const json: any[] = utils.sheet_to_json(worksheet, { header: 1 });

    const header = json[0] as string[];
    const rows = json.slice(1);

    const formattedData: ExcelData[] = rows.map((row) => ({
      detektor: row[header.indexOf('detektor')],
      X: parseFloat(row[header.indexOf('X')]),
      Y: parseFloat(row[header.indexOf('Y')]),
      riktning: parseInt(row[header.indexOf('riktning')], 10),
      tagID: row[header.indexOf('tag-ID')],
      tagtyp: parseInt(row[header.indexOf('tagtyp')], 10),
      sequenceNumber: parseInt(row[header.indexOf('sekv.nr')], 10),
      radioNumber: parseInt(row[header.indexOf('radionr')], 10),
      functionID: parseInt(row[header.indexOf('funk-ID')], 10),
      object: row[header.indexOf('objekt')],
      objectName: row[header.indexOf('objektnamn')] || '',
      equipmentRoom: row[header.indexOf('apparatrum')],
      equipmentRoomName: row[header.indexOf('apparatrumsnamn')],
      source: parseInt(row[header.indexOf('källa')], 10),
    }));

    // Group detectors and create tags with positions
    const detectorMap: Record<
      string,
      { tags: { tagID: string; decimalTagID: number; coordinates: [number, number] }[]; data: ExcelData }
    > = {};
    const tagsWithPositions: Record<string, { coordinates: [number, number] }> = {};

    formattedData.forEach((item) => {
      const decimalTagID = parseInt(item.tagID, 16); // Convert tag ID to decimal
      if (!detectorMap[item.detektor]) {
        detectorMap[item.detektor] = { tags: [], data: item };
      }
      detectorMap[item.detektor].tags.push({
        tagID: item.tagID,
        decimalTagID,
        coordinates: [item.X, item.Y],
      });

      // Add tag position to tagsWithPositions
      tagsWithPositions[parseInt(item.tagID, 16)] = {
        coordinates: [item.X, item.Y],
      };
    });

    const detectorsWithTags = Object.entries(detectorMap).map(([detector, { tags, data }]) => ({
      detector,
      tags,
      tagsLength: tags.length, // Add tags length
      properties: {
        direction: data.riktning,
        tagType: data.tagtyp,
        sequenceNumber: data.sequenceNumber,
        radioNumber: data.radioNumber,
        functionID: data.functionID,
        object: data.object,
        objectName: data.objectName,
        equipmentRoom: data.equipmentRoom,
        equipmentRoomName: data.equipmentRoomName,
        source: data.source,
      },
    }));

    // Store detectors with tags and tags with positions in separate localStorage keys
    localStorage.setItem('detectorsWithTags', JSON.stringify(detectorsWithTags));
    localStorage.setItem('tagsWithPositions', JSON.stringify(tagsWithPositions));

    const geoJson = {
      type: 'FeatureCollection',
      features: detectorsWithTags.map(({ detector, tags, tagsLength, properties }) => ({
        type: 'Feature',
        properties: {
          detector,
          tags: tags.map((tag) => tag.decimalTagID), // Use decimal tag IDs in GeoJSON
          tagsLength,
          ...properties,
        },
        geometry: {
          type: 'Point',
          coordinates: [tags[0]?.coordinates[0], tags[0]?.coordinates[1]], // Use first tag's coordinates
        },
      })),
    };

    setPointsGeoJsonData(geoJson);
    setDataLoaded(true);
  }, []);

  const findDetectorByTag = (decimalTagID: number): string | null => {
    const detectorsWithTags = JSON.parse(localStorage.getItem('detectorsWithTags') || '[]');
    for (const detector of detectorsWithTags) {
      if (detector.tags.some((tag: { decimalTagID: number }) => tag.decimalTagID === decimalTagID)) {
        return detector.detector;
      }
    }
    return null;
  };

  return { pointsGeoJsonData, dataLoaded, convertExcelToGeoJson, findDetectorByTag };
};

export default useExcelToGeoJson;
