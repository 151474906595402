import {TFunction} from 'i18next';
import {AlertScopeProps} from 'types/alerts/alertScopeProps.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {
  AlertDatum,
  GraphqlResponseAlertData,
  GraphqlResponseDateIntervalOverviewAlertData,
  OverviewAlertData
} from 'types/alerts/alertMapData.ts';
import {mapKeys, mapMDeep} from '@rescapes/ramda';
import {
  add,
  chain,
  compose,
  filter,
  find,
  fromPairs,
  groupBy,
  head,
  includes,
  indexOf,
  join,
  keys,
  lensProp,
  map,
  mapObjIndexed,
  over,
  prop,
  reduce,
  split,
  tail,
  toPairs,
  unnest,
  values,
  zipWith
} from 'ramda';
import {AlertTypeConfig} from 'types/alerts/alertTypeConfig.ts';
import {compact, findOrThrow, stringifyDate} from 'utils/functional/functionalUtils.ts';
import {AlertLevels} from 'types/alerts/alertLevels.ts';
import {simplyFetchFromGraph} from 'appUtils/alertUtils/graphqlQueryUtils.ts';
import {clsOrTypes} from 'appUtils/typeUtils/clsOrTypes.ts';
import {CemitTypename} from 'types/cemitTypename.ts';
import {getAlertDateIntervals} from './graphqlSingleAlertTypeQueries';

import {findFirstVehicleCollectionDeviceWithPointIds} from 'appUtils/alertUtils/alertUtils.ts';
import {VehicleCollectionDevice} from 'types/sensors/vehicleCollectionDevice';
import {AlertGaugeByTimePeriod} from 'types/alerts/alertGaugeByTimePeriod.ts';
import {DateIntervalDescription} from 'types/datetime/dateIntervalDescription.ts';
import {AlertTypeKey} from 'types/alerts/alertTypeKey.ts';
import {DateInterval} from 'types/propTypes/trainPropTypes/dateInterval';
import {differenceInMinutes, endOfDay, milliseconds, parseISO, startOfDay, subDays, subSeconds} from 'date-fns';
import {clsOrType} from 'appUtils/typeUtils/clsOrType';
import {createDateInterval} from 'classes/typeCrud/dateIntervalCrud.ts';
import {isWithinInterval} from 'date-fns/isWithinInterval';
import {AlertOverviewByTimePeriod, AlertOverviewByTimePeriodPrevious} from 'types/alerts/alertOverviewByTimePeriod.ts';

/**
 * Creates several graphql queries that are sent together to the server.
 * A queries are number AlertType * number of AlertLevels per AlertType * number of TrainGroup
 * These queries don't return positions, just counts of each alert
 * @param t
 * @param alertScopeProps
 */
export async function getAllTrainGroupsSummaryAlertGraphqlData(
  t: TFunction,
  alertScopeProps: AlertScopeProps
): Promise<Perhaps<{
  summaryAlertGraphqlResponseAlertData: GraphqlResponseAlertData[],
  overviewAlertGraphqlResponseAlertData: GraphqlResponseDateIntervalOverviewAlertData[]
}>> {

  const {alertTypeConfigs} = alertScopeProps.alertConfigProps;
  // Find the TrainFormation's VehicleCollectionDevice that has pointIds configured.
  // TODO There could be two VehicleCollectionDevices's (CDCs) on a TrainFormation, but
  // we only consider the first we find with point ids configured
  const vehicleCollectionDevice: VehicleCollectionDevice = findFirstVehicleCollectionDeviceWithPointIds(alertScopeProps);

  // The summary queries for all AlertTypeConfigs
  const summarySignalAggregations: string[] = alertSummarySignalAggregations(vehicleCollectionDevice, alertScopeProps, alertTypeConfigs);

  // The overview query. This only concerns RideComfort
  const rideComfortAlertTypeConfig = findOrThrow(
    (alertTypeConfig: AlertTypeConfig) => {
      return alertTypeConfig.alertTypeKey == AlertTypeKey.alertPointId;
    },
    alertTypeConfigs
  );
  // The dateInterval is always from now back 180 days for the overview
  const now = new Date();
  const overviewDateInterval = clsOrType<DateInterval>(CemitTypename.dateInterval, {
    start: subDays(now, 180),
    end: now
  });
  const overviewSignalAggregations: string[] = alertOverviewSignalAggregation(t, overviewDateInterval, vehicleCollectionDevice, alertScopeProps, rideComfortAlertTypeConfig);

  const pointsData = `
  query{
    ${join('\n', [...summarySignalAggregations, ...overviewSignalAggregations])}
  }`;
  try {
    const {data} = await simplyFetchFromGraph({
      query: pointsData
    });

    // Create each AlertGraphqlResponseAlertData, backreferencing the alertTypeConfig used for the query
    const _dataByAlertType = groupBy(
      ([key, value]: [string, any]) => {
        // Captures 'Days180', 'Days1', 'Days2To1'
        if (includes('Days', key)) {
          // Separate at overview results
          return 'Overview';
        }
        return head(split('_', key));
      },
      toPairs(data)
    );

    // Operate on the lists of each object, removing the key from the pair
    const dataByAlertType = map(
      fromPairs,
      mapMDeep(
        2,
        ([key, value]: [string, any]) => {
          return [join('_', tail(split('_', key))), value];
        },
        _dataByAlertType
      )
    );

    const summaryAlertGraphqlResponseAlertData = clsOrTypes<GraphqlResponseAlertData>(
      CemitTypename.alertGraphqlResponseAlertData,
      map((alertTypeConfig: AlertTypeConfig) => {
        const data = dataByAlertType[alertTypeConfig.labelShort];
        return {
          data,
          alertTypeConfig
        };
      }, alertTypeConfigs)
    );

    // For the overview queries, convert each time string to a date
    const overviewDataWithDatesByLevelUncombined: Record<string, AlertDatum[]> = map(
      (alertData: AlertDatum[]): AlertDatum[] => {
        return map(
          (alertDatum: AlertDatum): AlertDatum => {
            return over(
              lensProp('time'),
              (time: Perhaps<string | Date>): Date => {
                return parseISO(time as string);
              },
              alertDatum);
          },
          alertData);
      },
      dataByAlertType['Overview']
    );
    // For each level combine the data from the three queries.
    // So L0_Days180, L0_Days1, L0_Days2T01 values are all put into L0_Days180, and
    // likewise for the other levels
    // We do this because the *_Days1, *_Days2To1 queries were only made because of a shortcoming in the API
    // The _Days180 queries filtered out every date within the last two days, so we won't have any duplicates here
    const overviewDataWithDatesByLevel: Record<string, AlertDatum[]> = compose(
      // Combine the AlertDatum arrays of L0_Days180, L0_Days1, L0_Days2T01, and likewise for the other levels
      (obj: Record<string, [string, AlertDatum[]][]>) => {
        return map((pairValuesSets: [string, AlertDatum[]][]) => {
            return unnest(map(pair => pair[1], pairValuesSets));
          },
          obj
        );
      },
      (pairs: [string, AlertDatum[]][]): Record<string, [string, AlertDatum[]][]> => {
        return groupBy(
          ([key, _values]: [string, AlertDatum[]]) => {
            // Get L0, L1, L2, or L3
            return `${split('_', key)[0]}_Days180`;
          },
          pairs
        ) as Record<string, [string, AlertDatum[]][]>;
      },
      (obj: Record<string, AlertDatum[]>): [string, AlertDatum[]][] => toPairs(obj)
    )(overviewDataWithDatesByLevelUncombined);

    // Aggregate overview data by 1 day, 30, days, 90 days, and 180 days
    const overviewAggregationDateIntervals: Record<AlertOverviewByTimePeriod, DateInterval> = {
      [AlertOverviewByTimePeriod.Days1]: createDateInterval(subDays(overviewDateInterval.end, 1), overviewDateInterval.end),
      [AlertOverviewByTimePeriod.Days7]: createDateInterval(subDays(overviewDateInterval.end, 7), overviewDateInterval.end),
      [AlertOverviewByTimePeriod.Days14]: createDateInterval(subDays(overviewDateInterval.end, 14), overviewDateInterval.end),
      [AlertOverviewByTimePeriod.Days30]: createDateInterval(subDays(overviewDateInterval.end, 30), overviewDateInterval.end),
      [AlertOverviewByTimePeriod.Days90]: createDateInterval(subDays(overviewDateInterval.end, 90), overviewDateInterval.end),
      [AlertOverviewByTimePeriod.Days180]: overviewDateInterval,

      // Previous intervals relative to those above, except Days180. This lets us calculate rate of change
      [AlertOverviewByTimePeriodPrevious.Days2To1]: createDateInterval(
        subDays(overviewDateInterval.end, 2),
        subDays(overviewDateInterval.end, 1)),
      [AlertOverviewByTimePeriodPrevious.Days14To7]: createDateInterval(
        subDays(overviewDateInterval.end, 14),
        subDays(overviewDateInterval.end, 7)),
      [AlertOverviewByTimePeriodPrevious.Days28To14]: createDateInterval(
        subDays(overviewDateInterval.end, 28),
        subDays(overviewDateInterval.end, 14)),
      [AlertOverviewByTimePeriodPrevious.Days60To30]: createDateInterval(
        subDays(overviewDateInterval.end, 60),
        subDays(overviewDateInterval.end, 30)),
      [AlertOverviewByTimePeriodPrevious.Days180To90]: createDateInterval(
        subDays(overviewDateInterval.end, 180),
        subDays(overviewDateInterval.end, 90))
    };


    // Get the overview data that fits each overviewAggregationDateInterval
    const overviewDataBrokenDownByDateIntervalByLevel = map(
      (overviewDataWithDates: AlertDatum[]): Record<string, AlertDatum[]> => {
        return map(
          (overviewAggregationDateInterval: DateInterval) => {
            return filter(
              ({time}: {time: Date}) => {
                return isWithinInterval(time, overviewAggregationDateInterval);
              }, overviewDataWithDates
            );
          },
          overviewAggregationDateIntervals
        );
      },
      overviewDataWithDatesByLevel
    );

    // Combine the different levels to make a percent
    // ((L0) / (L0 + L1 + L2 + L3)) * 100
    const overviewAlertGraphqlResponseAlertData: GraphqlResponseDateIntervalOverviewAlertData[] = values(mapObjIndexed(
      (dateInterval: DateInterval, dateIntervalLabel: string) => {
        // TODO should be from AlertTypeConfig
        const levels = ['L0', 'L1', 'L2', 'L3'];
        // TODO temp for debugging
        const name = alertScopeProps.scopedTrainGroup.trainFormation.name;
        if (!name) {
          console.log(name);
        }
        const normalLevelIndex = indexOf('L0', levels);
        const sums: number[] = map(
          (levelLabel: string): number[] => {
            return reduce(add, 0, map(prop('count'), overviewDataBrokenDownByDateIntervalByLevel[`${levelLabel}_Days180`][dateIntervalLabel]));
          },
          levels
        );
        const total: number = reduce(
          add,
          0,
          sums
        );
        const percent: number = 100 * (sums[normalLevelIndex] / total);

        // Operational hour per day(or other) =( Count (all signals) x 20(sec) )/ (60secx60min) = Count (all signals) / (20x3600)
        // TODO this was used as a divident for operationHoursPerDay
        // const daysInDateInterval = differenceInDays(dateInterval.end, dateInterval.start)
        const operationHours = Math.round(total * 20 / 3600);

        return clsOrType<GraphqlResponseDateIntervalOverviewAlertData>(CemitTypename.graphqlResponseDateIntervalOverviewAlertData, {
            dateIntervalLabel,
            dateInterval,
            percent,
            operationHours,
            // Convert L1_Days180 to L1, etc
            overviewAlertData: clsOrType<OverviewAlertData>(CemitTypename.overviewAlertData, mapKeys(
              (label: string) => {
                return split('_', label)[0];
              },
              map((overviewDataBrokenDownByDateIntervalOfLevel: Record<string, AlertDatum[]>) => {
                return overviewDataBrokenDownByDateIntervalOfLevel[dateIntervalLabel];
              }, overviewDataBrokenDownByDateIntervalByLevel)
            ))
          }
        );
      },
      overviewAggregationDateIntervals
    ));

    return {
      summaryAlertGraphqlResponseAlertData,
      overviewAlertGraphqlResponseAlertData
    };
  } catch (error) {
    return undefined;
  }
}


/**
 * Creates a query for each alertTypeConfig for each alert level
 */
const alertSummarySignalAggregations = (
  vehicleCollectionDevice: VehicleCollectionDevice,
  alertScopeProps: AlertScopeProps,
  alertTypeConfigs: AlertTypeConfig[]
): string[] => {
  // Map over the alertTypeConfigs, which is a config for each alert type
  // We create a separate query for each alert type with the current date interval
  const signalAggregations: string[][] = map(
    (alertTypeConfig: AlertTypeConfig): string[] => {

      // Get the pointId of this alert type for the TrainFormation's sensor
      const pointId: string = vehicleCollectionDevice[alertTypeConfig.alertTypeKey as keyof VehicleCollectionDevice];

      // Get configured AlertGaugeByTimePeriod, either AlertGaugeByTimePeriod.today,
      // AlertGaugeByTimePeriod.week, or AlertGaugeByTimePeriod.month
      const alertGaugeTimePeriod: keyof AlertGaugeByTimePeriod = alertScopeProps.alertConfigProps.alertGaugeTimePeriod;

      // The AlertIntervalDescription specified by the user for TrainGroupFormationOnly or
      // the AlertIntervalDescription based on the TrainGroup for TrainGroupSingleTrainRun
      const dateIntervalDescription: DateIntervalDescription = alertScopeProps.alertTrainGroupProps.dateIntervalDescription!;

      const dateInterval: DateInterval = dateIntervalDescription.dateInterval!;
      // The alert levels of the AlertType
      const levels: (keyof AlertLevels)[] = keys(alertTypeConfig.alertLevelToAttribute);
      // The alert level keys, e.g. L0, L1, L2, L3
      const types: (keyof AlertLevels)[] = keys(
        alertTypeConfig.alertLevelToAttributeForTypeKeys ||
        alertTypeConfig.alertLevelToAttribute
      );

      // Gets components of the query that are dependent on dateInterval
      // filteredDurations is a single list describing the AlertGaugeByTimePeriod and a function to make
      // the correct date interval based upon it. E.g. today creates a 24-hour date interval
      // fromFunc similarly helps calculate the correct date interval
      // windows is a structure that tells us what window to use for the query based on the number of
      // seconds of dateInterval
      const {filteredDurations, fromFunc, windows} =
        getAlertDateIntervals(
          dateIntervalDescription,
          alertGaugeTimePeriod,
          dateInterval,
          true
        );
      const unit = alertTypeConfig.unit;

      // Create signalAggregations for each level/type for the single item in filteredDurations
      const signalAggregations: string[] = chain((duration) => {
        return zipWith(
          (level, type) => {
            // Use the interval of from - chosen duration to from or dateInterval.start if defined
            const [fromDate, toDate] = [
              fromFunc(duration, dateInterval),
              dateInterval.end
            ];
            const [fromStr, toStr] = map(stringifyDate, [fromDate, toDate]);
            const seconds = (toDate.getTime() - fromDate.getTime()) / 1000;
            const windowToUse = findOrThrow((win) => {
              return win.value >= seconds;
            }, windows);

            return `${alertTypeConfig.labelShort}_${level}_${duration.label}:signalsAggregation(
      where: { pointId:"${pointId}", type: "${type}", unit: ${unit} }
      aggregate: {
        from:"${fromStr}"
        to: "${toStr}"
        window: ${windowToUse.label}
      }
    ) {
      count
    }`;
          },
          levels,
          types
        );
      }, filteredDurations);

      return signalAggregations;
    },
    alertTypeConfigs
  );
  return unnest(signalAggregations);
};
const secondsPerDay = milliseconds({days: 1}) / 1000;

/**
 * Creates an overview querys for the RideComfort alert type
 * Since the overview needs stats for 1 day, 30 days, 90 days, and 180 days,
 * we query for everything from 180 days back to now and process the
 * query results later to split them up by the desired time periods
 * Returns 3 queries. 1 for 180 days, 1 for the last day, and 1 for the previous day
 * The latter two are needed so we can make the query window HOUR_1 and match the summary queries
 */
const alertOverviewSignalAggregation = (
  t: TFunction,
  dateInterval180Days: DateInterval,
  vehicleCollectionDevice: VehicleCollectionDevice,
  alertScopeProps: AlertScopeProps,
  alertTypeConfig: AlertTypeConfig
): string[] => {

  // Get the pointId of this alert type for the TrainFormation's sensor
  const pointId = vehicleCollectionDevice[alertTypeConfig.alertTypeKey];

  // Get configured AlertGaugeByTimePeriod, either AlertGaugeByTimePeriod.today,
  // AlertGaugeByTimePeriod.week, or AlertGaugeByTimePeriod.month
  const alertGaugeTimePeriod: keyof AlertGaugeByTimePeriod = alertScopeProps.alertConfigProps.alertGaugeTimePeriod;


  // A DateIntervalDescription based on dateInterval
  const dateIntervalDescription: DateIntervalDescription = clsOrType<DateIntervalDescription>(CemitTypename.dateIntervalDescription, {
    label: '180 days',
    durationText: '180 days',
    duration: differenceInMinutes(dateInterval180Days.end, dateInterval180Days.start),
    dateInterval: dateInterval180Days
  });

  // The alert levels of the AlertType
  const levels: (keyof AlertLevels)[] = keys(alertTypeConfig.alertLevelToAttribute);
  // The alert level keys, e.g. L0, L1, L2, L3
  const types: (keyof AlertLevels)[] = keys(
    alertTypeConfig.alertLevelToAttributeForTypeKeys ||
    alertTypeConfig.alertLevelToAttribute
  );

  // windows is a structure that tells us what window to use for the query based on the number of
  // seconds of dateInterval
  const {windows} =
    getAlertDateIntervals(
      dateIntervalDescription,
      alertGaugeTimePeriod,
      dateInterval180Days,
      true
    );
  const unit = alertTypeConfig.unit;

  // Create signalAggregations for each level/type for the 180 day duration
  const signalAggregations: string[] = unnest(zipWith(
    (level, type) => {

      // Query from the start of 180 days back to the end of 3 days back the day selected by the user
      const [fromStr180To3Days, toStr180To3Days] = map(stringifyDate, [
        startOfDay(dateInterval180Days.start),
        endOfDay(subDays(dateInterval180Days.end, 2))
      ]);
      const seconds = (dateInterval180Days.end.getTime() - dateInterval180Days.start.getTime()) / 1000;
      // This gets the DAY_1 window
      const windowToUseFor180 = findOrThrow((win) => {
        return win.value >= seconds;
      }, windows);
      // Query from 24 hours back to the end time selected by the user
      const [fromStrHours24To0, toStrHours24To0] = map(stringifyDate, [subDays(dateInterval180Days.end, 1), subSeconds(dateInterval180Days.end, 1)]);
      // Query from 48 hours back to 24 hours back
      const back48Hours = subDays(dateInterval180Days.end, 2)
      const [fromStrHours48To24, toStrHours48To24] = map(stringifyDate, [back48Hours, subSeconds(subDays(dateInterval180Days.end, 1), 1)]);
      // Query from the start of 2 days back to 48 hours back. We need this to catch the hours between
      // the end of the Days180to3 query and the start of the Hours48To24 query
      // If this interval is 0, don't query
      const [fromStrDays2ToHours48, toStrDays2ToHours48] = (back48Hours.getTime() - startOfDay(back48Hours).getTime()) > 0 ?
        map(stringifyDate, [startOfDay(back48Hours), subSeconds(back48Hours, 1)]) : [undefined, undefined]
      // This gets the MINUTE_1 window
      const windowToUseForHours = findOrThrow((win) => {
        return win.value >= secondsPerDay;
      }, windows);

      // Create a 180-to-2-day query and a 24 hour query, 48 to 24 hour query, and
      // a query that capture the remains of the calendar day before the start of the 48 to 24 hour query
      // The latter two are needed because the 180 day query uses the DAY_1 window
      // and the two 24 hour periods need to use an HOUR_1 window
      return compact([
        // Last 180 days to end of 3 days back
        `${alertTypeConfig.labelShort}_${level}_Days180To3:signalsAggregation(
      where: { pointId:"${pointId}", type: "${type}", unit: ${unit} }
      aggregate: {
        from:"${fromStr180To3Days}"
        to: "${toStr180To3Days}"
        window: ${windowToUseFor180.label}
      }
    ) {
      time
      count
      }`,
        // Start of 2 days back Last 48 hours. Don't include if the last 48 to 24 hours starts exactly
        // at the start of the day
        fromStrDays2ToHours48 ? `${alertTypeConfig.labelShort}_${level}_Days2ToHours48:signalsAggregation(
      where: { pointId:"${pointId}", type: "${type}", unit: ${unit} }
      aggregate: {
        from:"${fromStrDays2ToHours48}"
        to: "${toStrDays2ToHours48}"
        window: ${windowToUseForHours.label}
      }
    ) {
      time
      count
      }`: undefined,
        // Last 48 to 24 hours
        `${alertTypeConfig.labelShort}_${level}_Days2To1:signalsAggregation(
      where: { pointId:"${pointId}", type: "${type}", unit: ${unit} }
      aggregate: {
        from:"${fromStrHours48To24}"
        to: "${toStrHours48To24}"
        window: ${windowToUseForHours.label}
      }
    ) {
      time
      count
      }`,
        // Last 24 Hours
        `${alertTypeConfig.labelShort}_${level}_Days1:signalsAggregation(
      where: { pointId:"${pointId}", type: "${type}", unit: ${unit} }
      aggregate: {
        from:"${fromStrHours24To0}"
        to: "${toStrHours24To0}"
        window: ${windowToUseForHours.label}
      }
    ) {
      time
      count
      }`,
      ]);
    },
    levels,
    types
  ));

  return signalAggregations;
};



