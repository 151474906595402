import {TrainGroup} from 'types/trainGroups/trainGroup';
import {TFunction} from 'i18next';
import {AlertScopeProps} from 'types/alerts/alertScopeProps';
import {CrudList} from 'types/crud/crudList';
import {GraphqlResponseAlertData, GraphqlResponseDateIntervalOverviewAlertData} from 'types/alerts/alertMapData';
import {AlertGaugeByTimePeriod} from 'types/alerts/alertGaugeByTimePeriod';
import {ifElse, indexBy, indexOf, prop, when, zipWith} from 'ramda';
import {getAlertGaugeByTimePeriod} from 'appUtils/alertUtils/alertQueryUtils';
import {setTrainGroupAlertSummariesWithQueryData} from 'async/alertAsync/alertHooks';
import {getAllTrainGroupsSummaryAlertGraphqlData} from './graphqlAlertOverviewAndSummaryQueries';
import {AlertTypeConfig} from 'types/alerts/alertTypeConfig';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {AlertBaseGauge} from 'types/alerts/alertGauge.ts';

/**
 * Queries for the Alert data of the given AlertScopeProps, which is scoped to a single TrainGroup
 * Returns overview and summary alerts for the TrainGroup
 * Overview are the Ride Comfort Alert values for 1 day, 30 days, 90 days, and 180 days
 * Summary is a summary for each alert type for the current date interval
 * @param t
 * @param alertScopePropSets
 * @param trainGroupCrudList
 */
export const queryAlertApiForAllOverviewAndSummaryAlertTypesAndMutate = async <T extends TrainGroup>(
  t: TFunction,
  alertScopeProps: AlertScopeProps<T>,
  trainGroupCrudList: CrudList<TrainGroup>
): Promise<void> => {

  // Makes a separate query the TrainGroup in the scope of alertScopeProps
  // querying for alert overview data for the last day, 30 days, 90 days, and 180 days
  // and query for summary data for each alert type for the current date interval
  const {summaryAlertGraphqlResponseAlertData, overviewAlertGraphqlResponseAlertData}: {
    summaryAlertGraphqlResponseAlertData: GraphqlResponseAlertData[],
    overviewAlertGraphqlResponseAlertData: GraphqlResponseDateIntervalOverviewAlertData[]
  } =
    await getAllTrainGroupsSummaryAlertGraphqlData(t, alertScopeProps);

  if (summaryAlertGraphqlResponseAlertData) {
    const {alertTypeConfigs}: {alertTypeConfigs: AlertTypeConfig[]} = alertScopeProps.alertConfigProps;

    // Zip each alertTypeConfig with the corresponding alertGraphqlResponseAlertDataSet
    // to create an AlertGaugeByTimePeriod
    const summaryAlertGaugesByTimePeriod: AlertGaugeByTimePeriod[] = zipWith(
      (
        alertTypeConfig: AlertTypeConfig,
        alertGraphqlResponseAlertData: GraphqlResponseAlertData
      ): AlertGaugeByTimePeriod => {

        // Find the parent AlertTypeConfig response data if a parentAlertTypeConfig is defined
        const parentOrNormalAlertGraphqlResponseAlertData: Perhaps<GraphqlResponseAlertData> = when(
          Boolean,
          (parentAlertTypeConfig: AlertTypeConfig): GraphqlResponseAlertData => {
            const alertTypeConfigLookup = indexBy(prop('alertTypeKey'), alertTypeConfigs);
            const parentAlertTypeConfigIndex = indexOf(alertTypeConfigLookup[parentAlertTypeConfig.alertTypeKey], alertTypeConfigs);
            return summaryAlertGraphqlResponseAlertData[parentAlertTypeConfigIndex] as GraphqlResponseAlertData;
          }
        )(alertTypeConfig.parentAlertTypeConfig) as Perhaps<GraphqlResponseAlertData>;

        // Returns a AlertGaugeByTimePeriod, which has an AlertBaseGauge for each time period, i.e. day, week, and month
        // Each AlertBaseGauge has a key for each alert type that is a AlertGaugeValue, which has a percent
        // of that alert type out of the total and a total number of alerts of that type.
        // If parentOrNormalAlertGraphqlResponseAlertData, it uses the parent's alert types total
        // as the denominator for calculating each percent
        return getAlertGaugeByTimePeriod(
          alertGraphqlResponseAlertData,
          parentOrNormalAlertGraphqlResponseAlertData
        );
      },
      alertTypeConfigs,
      summaryAlertGraphqlResponseAlertData
    );

    // Sets the trainGroup.alertScopeSummaryProps
    setTrainGroupAlertSummariesWithQueryData(
      alertScopeProps,
      summaryAlertGaugesByTimePeriod,
      overviewAlertGraphqlResponseAlertData,
      trainGroupCrudList
    );
  }
};
