import * as XLSX from "xlsx";
import {saveAs} from "file-saver";

export function convertToExcel(xlxsData) {
  const workSheet = XLSX.utils.json_to_sheet(xlxsData);
  const workBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet1');
  const bookBlob = XLSX.write(workBook, {bookType: 'xlsx', type: 'array'});
  const blob = new Blob([bookBlob], {type: 'application/octet-stream'});
  saveAs(blob, 'export.xlsx');
}
