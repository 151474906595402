import { useEffect, useMemo, useState } from 'react';
import { useQueries } from '@tanstack/react-query';
import { TagData } from '../types';
import { throwUnlessDefined } from 'utils/functional/functionalUtils.ts';

// Function to fetch a specific page of tag data
const fetchPageData = async (referenceId: string, page: number): Promise<TagData[]> => {
  const REACT_APP_DETECTOR_API_URL = throwUnlessDefined(process.env.REACT_APP_DETECTOR_API_URL);
  const detectorApiUrl = `${REACT_APP_DETECTOR_API_URL}?train_reference_ids=${referenceId}&orderBy=-detection-time&page=${page}&pageSize=200`;

  const response = await fetch(detectorApiUrl, {
    method: 'GET',
    headers: {
      accept: 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error(`Network response was not ok for page ${page}`);
  }

  const result = await response.json();

  if (Array.isArray(result)) {
    return result;
  } else if (result && Array.isArray(result.data)) {
    return result.data;
  } else {
    throw new Error(`Unexpected API response structure on page ${page}`);
  }
};

// Custom hook to fetch all pages for a given referenceId using React Query
const useFetchedLoopTags = (referenceId: string) => {
  const [error, setError] = useState<string | null>(null);

  // Create an array of queries for pages 1 to 10
  const queries = useMemo(
    () =>
      Array.from({ length: 10 }, (_, index) => ({
        queryKey: ['fetchTags', referenceId, index + 1],
        queryFn: () => fetchPageData(referenceId, index + 1),
        enabled: !!referenceId, // Only run queries if referenceId is available
      })),
    [referenceId]
  );

  // Use React Query's `useQueries` to fetch each page
  const queryResults = useQueries({ queries });

  // Track loading and error states
  const isLoading = queryResults.some((query) => query.isLoading);
  const isError = queryResults.some((query) => query.isError);
  const combinedError = queryResults.find((query) => query.isError)?.error;

  useEffect(() => {
    if (isError && combinedError) {
      setError((combinedError as Error).message);
    }
  }, [isError, combinedError]);

  // Combine data from all page results when they're all successful
  const data = useMemo(() => {
    if (queryResults.every((query) => query.isSuccess)) {
      return queryResults.flatMap((query) => query.data || []);
    }
    return [];
  }, [queryResults]);

  // Detailed logging for each query result for debugging
  useEffect(() => {
    // console.log('Query results:');
    // queryResults.forEach((result, index) => {
    //   console.log(`Page ${index + 1}:`);
    //   // console.log('  isLoading:', result.isLoading);
    //   // console.log('  isError:', result.isError);
    //   // console.log('  data:', result.data);
    //   // console.log('  error:', result.error);
    // });
    // console.log('Combined Data:', data);
    // console.log('Overall isLoading:', isLoading);
    // console.log('Overall Error:', error);
  }, [queryResults, data, isLoading, error]);

  return {
    data: data, // Only return up to 10 items
    isLoading,
    error,
  };
};

export default useFetchedLoopTags;
