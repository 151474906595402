import {TrainGroup} from 'types/trainGroups/trainGroup';
import LoaderWithText from 'components/loading/LoaderWithText.tsx';
import {LoadingStatusEnum} from 'types/apis/loadingStatusEnum.ts';
import {Perhaps} from 'types/typeHelpers/perhaps';
import {Stack} from '@mui/material';
import {map} from 'ramda';
import {AlertBaseGauge} from 'types/alerts/alertGauge';
import {
  TrainAppTrainComponentDependencyProps
} from 'types/propTypes/appPropTypes/trainAppPropTypes/trainAppTrainComponentDependencyProps';
import {StateSetter} from 'types/hookHelpers/stateSetter';
import {TrainGroupOnlyTrainFormation} from 'types/trainGroups/trainGroupOnlyTrainFormation';
import AlertErrorLevelsComponent
  from 'components/apps/trainAppComponents/trainAppBoardComponents/alertBoardComponents/alertComponents/AlertErrorLevelsComponent.tsx';
import {AlertTypeConfig} from 'types/alerts/alertTypeConfig';
import {AlertGaugeByTimePeriod} from 'types/alerts/alertGaugeByTimePeriod.ts';
import {MouseEvent, useState} from 'react';

export interface TrainGroupAlertStatusProps {
  trainGroup: TrainGroup;
  // Tells the parent when a child tooltip is activated
  setChildTooltipActive: StateSetter<boolean>;
  // Allows adding the TrainGroup if not active
  handleAddTrainGroupOnlyTrainFormationToFilter: (
    trainGroupFormation: TrainGroupOnlyTrainFormation
  ) => void;
  // Allows setting the AlertConfigType for the Application
  setAlertTypeConfig: StateSetter<AlertTypeConfig>;
}

/**
 * Shows collapsed or expanded alert summaries for each trainGroups in the list of trainGroups
 * @param loading
 * @param trainProps
 * @param appProps
 * @param trainProps
 * @param componentProps
 * @constructor
 */
const TrainGroupAlertStatus = (
  {
    loading,
    appProps,
    trainProps,
    componentProps
  }: TrainAppTrainComponentDependencyProps<TrainGroupAlertStatusProps>) => {
  const trainGroup: Perhaps<TrainGroup> = componentProps?.trainGroup;
  if (
    loading ||
    trainGroup?.alertScopeSummaryProps?.alertTrainGroupProps?.alertGraphqlStatus !==
    LoadingStatusEnum.complete
  ) {
    return (
      <LoaderWithText
        {...{sx: {minHeight: trainGroup?.actvity?.isListingExpanded ? '45px' : '15px'}}}
      />
    );
  }

  const alertGauges: AlertBaseGauge[] = map(
    (alertGaugesByTimePeriod: AlertGaugeByTimePeriod) => {
      return alertGaugesByTimePeriod.today;
    },
    trainGroup!.alertScopeSummaryProps!.alertTrainGroupProps!.alertGaugesByTimePeriod!
  );

  // Enables showing totals if ctrl is pressed when entering
  const [debugShowTotals, setDebugShowTotals] = useState<boolean>(false);
  const onHover = (e: MouseEvent<HTMLDivElement, MouseEvent>, enter: boolean) => {
    setDebugShowTotals(enter && e.ctrlKey);
  };


  const isExpanded = appProps.areAllTrainGroupsExpanded || trainGroup.activity.isListingExpanded;
  const alertErrorLevelComponents = map((alertGauge: AlertBaseGauge) => {
    return (
      <AlertErrorLevelsComponent
        key={alertGauge.alertTypeConfig.label}
        {...{
          loading,
          trainGroup,
          alertGauge,
          alertErrorLevelConfigs: alertGauge.alertTypeConfig!.attributeAlertLevelConfigs,
          // Only show most severe alert that has a non-zero value
          // or else show the lowest level or none
          // An accordion control expands TrainGroupAlertStatus
          expandable: true,
          isExpanded,
          // For alert summaries, clicking changes the applications selected alertTypeConfig
          // and activates the TrainGroup if not activated
          onClickSelectTrainGroupAndAlertTypeConfig: true,
          // Is this the active AlertTypeConfig in the application
          isActiveAlertTypeConfig:
            trainProps.alertConfigProps.alertTypeConfig.alertTypeKey ===
            alertGauge.alertTypeConfig?.alertTypeKey,
          setChildTooltipActive: componentProps.setChildTooltipActive,
          handleAddTrainGroupOnlyTrainFormationToFilter:
          componentProps.handleAddTrainGroupOnlyTrainFormationToFilter,
          // Used to activate the AlertTypeConfig if onClickSelectTrainGroupAndAlertTypeConfig
          setAlertTypeConfig: componentProps.setAlertTypeConfig,
          debugShowTotals,
        }}
      />
    );
  }, alertGauges);
  return <Stack {...{
    direction: 'row',
    onMouseEnter: (e: MouseEvent<HTMLDivElement, MouseEvent>) => onHover(e, true),
    onMouseLeave: (e: MouseEvent<HTMLDivElement, MouseEvent>) => onHover(e, false),
  }}>{alertErrorLevelComponents}</Stack>;
};
export default TrainGroupAlertStatus;
