import  {useContext, useState} from 'react';
import CemitThemeContext from 'theme/CemitThemeContext.ts';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {CemitAppOrganizationDependencyProps} from 'types/propTypes/appPropTypes/cemitAppPropTypes/cemitAppOrganizationDependencyProps.ts';
import StatusPageContainer from './StatusPageContainer.tsx';
import { ThemeProvider } from '@mui/material';
import {ThemeEnum, themeLookup} from 'theme/lightDarkTheme.ts';

const queryClient = new QueryClient();

const SoftprioAppContainer = ({

  organizationProps,
}: CemitAppOrganizationDependencyProps) => {
  const [localTheme, setLocalTheme] = useState<ThemeEnum>(ThemeEnum.dark);

  const toggleLocalTheme = () => {
    setLocalTheme((prev) => (prev === ThemeEnum.softpriolight ? ThemeEnum.dark : ThemeEnum.softpriolight));
  };

  const realtimeSpaceId = organizationProps?.organization?.realtimeSpaceId;
  return (
    <QueryClientProvider client={queryClient}>
      {/*<ReactQueryDevtools initialIsOpen={false}>*/}
      <ThemeProvider theme={themeLookup[localTheme]}>
      <StatusPageContainer spaceId={realtimeSpaceId!} pointId={''} toggleLocalTheme={toggleLocalTheme} localTheme={localTheme}/>
      </ThemeProvider>
      {/*</ReactQueryDevtools>*/}
    </QueryClientProvider>
  );
};

export default SoftprioAppContainer;
