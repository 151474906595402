import { useQuery } from '@tanstack/react-query';
import { TagData } from '../types';
import { throwUnlessDefined } from 'utils/functional/functionalUtils';

const REACT_APP_DETECTOR_BASE_API_URL = throwUnlessDefined(process.env.REACT_APP_DETECTOR_BASE_API_URL);
const detectorRssiValueUrl = `${REACT_APP_DETECTOR_BASE_API_URL}average_rssi`;

const fetchRssiValues = async (): Promise<TagData[]> => {
  const response = await fetch(detectorRssiValueUrl, {
    method: 'GET',
    headers: {
      accept: 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error(`Network response was not ok: ${response.statusText}`);
  }

  return response.json();
};

const useFetchRssiValue = (shouldFetch: boolean = true) => {
  const { isLoading, error, data } = useQuery<TagData[]>({
    queryKey: ['fetchRssiValues'],
    queryFn: fetchRssiValues,
    enabled: shouldFetch, // Only fetch when shouldFetch is true
  });

  return {
    data,
    isLoading,
    error,
  };
};
export default useFetchRssiValue